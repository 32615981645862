import { getCameraFrame } from '../services/media';
import { sendCameraFrame, ISendCameraFrameResponseBody } from '../api/frame';
import { blobToBase64 } from '../utils/blob';
import { Base64 } from '../hooks/useExtractFaceEmotions';

export const postCameraFrame = async (userId: string): Promise<[ISendCameraFrameResponseBody, Base64]> => {
  const blob = await getCameraFrame({ video: true });
  const base64 = await blobToBase64(blob);
  const res = await sendCameraFrame(userId, base64);
  return [res, base64];
};
