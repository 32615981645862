import { useEffect, useState } from 'react';

import './LoadingScreen.css';
import Logo from '../Logo/Logo';

interface LoadingScreenProps {
  width?: string;
  startFinishAnimation: boolean;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ width = '24vw', startFinishAnimation }) => {
  const [loadingClass, setLoadingClass] = useState('loading-screen animate-in');

  useEffect(() => {
    if (!startFinishAnimation) return;

    setLoadingClass('loading-screen animate-out');
  }, [startFinishAnimation]);

  const logoWidth = '120px'; // `calc(${width} / 3)`;

  return (
    <div className={loadingClass}>
      <div style={{ width, height: 'auto' }}>
        <Logo className="logo" style={{ width: logoWidth, height: 'auto' }} />
        <div className="main">
          <div className="linear-loader grey">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;

// import { useEffect, useState } from 'react';

// import './LoadingScreen.css';
// import Logo from '../Logo/Logo';

// interface LoadingScreenProps {
//   width?: string;
//   startFinishAnimation: boolean;
// }

// const LoadingScreen: React.FC<LoadingScreenProps> = ({ width = '16vw', startFinishAnimation }) => {
//   const [loadingClass, setLoadingClass] = useState('loading-screen animate-in');

//   useEffect(() => {
//     if (!startFinishAnimation) return;

//     setLoadingClass('loading-screen animate-out');
//   }, [startFinishAnimation]);

//   return (
//     <div className={loadingClass}>
//       <div style={{ width, height: '5rem' }}>
//         <Logo className="logo" style={{ width: '140px' }} />
//         <div className="main">
//           <div className="linear-loader grey">
//             <div></div>
//             <div></div>
//           </div>
//         </div>
//         {/* <span className="loader"></span> */}
//       </div>
//     </div>
//   );
// };

// export default LoadingScreen;
