import { useContext, useEffect, useState } from 'react';

import { useSocket } from './useSocket';
import { getTopEmotions, createDescription } from '../services/hume';
import { AppContext } from '../contextApp';
import { EventLogType } from '../models/eventLog';
import { Emotion, getStableEmotions } from '../utils/hume';
import { blobToBase64 } from '../utils/blob';
import { sendCameraFrame } from '../api/frame';
import { VideoRecorder } from '../utils/videoRecorder';

const humeUrl = import.meta.env.VF_HUME_API_URL || 'wss://api.hume.ai/v0/stream/models';
const humeApiKey = import.meta.env.VF_HUME_API_KEY || '';

export type Base64 = string | ArrayBuffer | null;

interface IHumeFrame {
  base64: Base64;
  timestamp: number;
}

export const useExtractFaceEmotions = (userId: string, isFirstFrameSent: boolean, recorder: VideoRecorder | null, isFaceOk: boolean = true) => {
  const { addEventLog } = useContext(AppContext);
  const [stableEmotions, setStableEmotions] = useState<Emotion[]>([]);
  const [frames, setFrames] = useState<IHumeFrame[]>([]);
  const [currentFrame, setCurrentFrame] = useState<Base64 | null>(null);
  const [currentFrameTimestamp, setCurrentFrameTimestamp] = useState<number>(0);

  const onMessage = (dataStringified: string) => {
    const data = JSON.parse(dataStringified);

    const predictions = data.face?.predictions;
    const emotions: Emotion[] = predictions?.[0]?.emotions;
    const arePredictionsSuccessful = !!predictions && emotions.length > 0;
    const topEmotions = arePredictionsSuccessful ? getTopEmotions(emotions) : [];

    const [currentStableEmotions, areUpdated] = arePredictionsSuccessful ? getStableEmotions(emotions, stableEmotions) : [stableEmotions, false];
    if (arePredictionsSuccessful && areUpdated) {
      setStableEmotions(currentStableEmotions);
    }
    const emotionsDescription = createDescription(currentStableEmotions);

    addEventLog([
      {
        type: EventLogType.USER_FACE_EMOTIONS,
        timestamp: currentFrameTimestamp,
        data: {
          success: arePredictionsSuccessful,
          face: data.face,
          topEmotions,
          emotionsDescription,
        },
      },
    ]);
  };

  const wsOptions = {
    onMessage,
  };
  const [ws] = useSocket(`${humeUrl}?apiKey=${humeApiKey}`, wsOptions, isFaceOk);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;

    if (ws && recorder) {
      interval = setInterval(() => sendFrameToHume(recorder, ws, isFirstFrameSent, setCurrentFrameTimestamp), 500);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [ws, setCurrentFrameTimestamp, isFirstFrameSent, recorder]);

  useEffect(() => {
    if (frames.length !== 0 && !currentFrame) {
      const [image, ...remainingImages] = frames;
      const { base64, timestamp } = image;
      setFrames(remainingImages);
      setCurrentFrame(base64);
      saveFrame(base64, timestamp, setCurrentFrame);
    }
  }, [frames]);

  const sendFrameToHume = async (
    recorder: VideoRecorder | null,
    socket: WebSocket | null,
    isFirstFrameSent: boolean,
    setTimestamp: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (document.hidden || !isFirstFrameSent || !recorder) return;

    const timestamp = Date.now();
    const photoBlob = await recorder.takePhoto();
    const base64 = await blobToBase64(photoBlob);
    setFrames((prevList: IHumeFrame[]) => (prevList.length < 10 ? [...prevList, { base64, timestamp }] : [...prevList]));
    setTimestamp(timestamp);

    if (!socket || socket.readyState !== socket.OPEN) return;

    socket.send(
      JSON.stringify({
        models: {
          face: {},
        },
        data: base64,
      })
    );
  };

  const saveFrame = async (base64: Base64, timestamp: number, setCurrentFrame: React.Dispatch<React.SetStateAction<Base64 | null>>) => {
    try {
      await sendCameraFrame(userId, base64, timestamp);
    } catch (error) {
      console.error(error);
    } finally {
      setCurrentFrame(null);
    }
  };
};
