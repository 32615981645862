import { Base64 } from '../hooks/useExtractFaceEmotions';
import { baseUrl } from './base';

export interface ISendCameraFrameResponseBody {
  frame_url: string;
  message: string;
  timestamp: number;
}

export const sendCameraFrame = async (userId: string, base64: Base64, timestamp: number | null = null): Promise<ISendCameraFrameResponseBody> => {
  const res = await fetch(`${baseUrl}/frame/${userId}`, {
    method: 'POST',
    headers: {
      authorization: import.meta.env.VF_DM_API_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ base64, timestamp }),
  });

  return res.json();
};
