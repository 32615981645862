import { useEffect, useState } from 'react';
import { LANGUAGES } from '../../constants';
import { useTranslation } from 'react-i18next';

import { LanguageMenuDropdown } from './LanguageMenuDropdown';

const LanguageMenu = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.resolvedLanguage || '');

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  return <LanguageMenuDropdown options={LANGUAGES} setSelectedLanguage={setSelectedLanguage} selectedLanguage={selectedLanguage} />;
};

export default LanguageMenu;
