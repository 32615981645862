import { useEffect, useContext } from 'react';
import { AppContext } from '../contextApp';

export const ExitMediaMode = ({ runtime, messageType }) => {
  const { showFixedMedia, setShowFixedMedia } = useContext(AppContext);

  useEffect(() => {
    if (showFixedMedia) {
      setShowFixedMedia(false);
      runtime.interact({ type: messageType, payload: '' });
    }
  }, []);

  return <></>;
};
