// https://github.com/HumeAI/hume-api-examples/blob/main/typescript-streaming-sandbox/lib/data/emotion.ts
export type Emotion = {
  name: EmotionName;
  score: number;
};

export type EmotionName =
  | 'Admiration'
  | 'Adoration'
  | 'Aesthetic Appreciation'
  | 'Amusement'
  | 'Anger'
  | 'Anxiety'
  | 'Awe'
  | 'Awkwardness'
  | 'Boredom'
  | 'Calmness'
  | 'Concentration'
  | 'Confusion'
  | 'Contemplation'
  | 'Contempt'
  | 'Contentment'
  | 'Craving'
  | 'Desire'
  | 'Determination'
  | 'Disappointment'
  | 'Disgust'
  | 'Distress'
  | 'Doubt'
  | 'Ecstasy'
  | 'Embarrassment'
  | 'Empathic Pain'
  | 'Entrancement'
  | 'Envy'
  | 'Excitement'
  | 'Fear'
  | 'Guilt'
  | 'Horror'
  | 'Interest'
  | 'Joy'
  | 'Love'
  | 'Nostalgia'
  | 'Pain'
  | 'Pride'
  | 'Realization'
  | 'Relief'
  | 'Romance'
  | 'Sadness'
  | 'Satisfaction'
  | 'Shame'
  | 'Surprise (negative)'
  | 'Surprise (positive)'
  | 'Sympathy'
  | 'Tiredness'
  | 'Triumph';

// https://github.com/HumeAI/hume-api-examples/blob/main/typescript-streaming-sandbox/lib/utilities/emotionUtilities.ts
export type EmotionInfo = {
  name: EmotionName;
  descriptor?: string;
};

export const CANONICAL_EMOTION_NAMES: EmotionName[] = [
  'Admiration',
  'Adoration',
  'Aesthetic Appreciation',
  'Amusement',
  'Anger',
  'Anxiety',
  'Awe',
  'Awkwardness',
  'Boredom',
  'Calmness',
  'Concentration',
  'Confusion',
  'Contemplation',
  'Contempt',
  'Contentment',
  'Craving',
  'Desire',
  'Determination',
  'Disappointment',
  'Disgust',
  'Distress',
  'Doubt',
  'Ecstasy',
  'Embarrassment',
  'Empathic Pain',
  'Entrancement',
  'Envy',
  'Excitement',
  'Fear',
  'Guilt',
  'Horror',
  'Interest',
  'Joy',
  'Love',
  'Nostalgia',
  'Pain',
  'Pride',
  'Realization',
  'Relief',
  'Romance',
  'Sadness',
  'Satisfaction',
  'Shame',
  'Surprise (negative)',
  'Surprise (positive)',
  'Sympathy',
  'Tiredness',
  'Triumph',
];

const DESCRIPTOR_MAP: Map<EmotionName, string | undefined> = new Map([
  ['Admiration', 'Admiring'],
  ['Adoration', 'Adoring'],
  ['Aesthetic Appreciation', undefined],
  ['Amusement', 'Amused'],
  ['Anger', 'Angry'],
  ['Anxiety', 'Anxious'],
  ['Awe', undefined],
  ['Awkwardness', 'Awkward'],
  ['Boredom', 'Bored'],
  ['Calmness', 'Calm'],
  ['Concentration', undefined],
  ['Confusion', 'Confused'],
  ['Contemplation', 'Comptemplative'],
  ['Contempt', 'Contemptful'],
  ['Contentment', 'Contented'],
  ['Craving', 'Craving'],
  ['Desire', 'Desirous'],
  ['Determination', 'Determined'],
  ['Disappointment', 'Disappointed'],
  ['Disgust', 'Disgusted'],
  ['Distress', 'Distressed'],
  ['Doubt', 'Doubtful'],
  ['Ecstasy', 'Ecstatic'],
  ['Embarrassment', 'Embarrassed'],
  ['Empathic Pain', undefined],
  ['Entrancement', 'Entranced'],
  ['Envy', 'Envious'],
  ['Excitement', 'Excited'],
  ['Fear', 'Fearful'],
  ['Guilt', 'Guilty'],
  ['Horror', 'Horrified'],
  ['Interest', 'Interested'],
  ['Joy', 'Joyful'],
  ['Love', 'Loving'],
  ['Nostalgia', 'Nostalgic'],
  ['Pain', 'Pained'],
  ['Pride', 'Prideful'],
  ['Realization', undefined],
  ['Relief', 'Relieved'],
  ['Romance', 'Romantic'],
  ['Sadness', 'Sad'],
  ['Satisfaction', 'Satisfied'],
  ['Shame', 'Shameful'],
  ['Surprise (negative)', 'Surprised'],
  ['Surprise (positive)', 'Surprised'],
  ['Sympathy', 'Sympathetic'],
  ['Tiredness', 'Tired'],
  ['Triumph', 'Triumphant'],
]);

export type Embedding = number[];

export function getEmotionDescriptor(name: EmotionName): string | undefined {
  return DESCRIPTOR_MAP.get(name);
}

// https://github.com/HumeAI/hume-api-examples/blob/main/typescript-streaming-sandbox/lib/utilities/embeddingUtilities.ts
export function emotionsToEmbedding(emotions: Emotion[]): Embedding {
  const scoreMap = emotionsToScoreMap(emotions);
  const embedding: Embedding = [];
  for (let i = 0; i < CANONICAL_EMOTION_NAMES.length; i++) {
    const emotionName = CANONICAL_EMOTION_NAMES[i];
    const score = scoreMap.get(emotionName);
    if (score === undefined) {
      console.error(`Could not find emotion ${emotionName} in embedding`);
      break;
    }
    embedding.push(score);
  }
  return embedding;
}

export function emotionDist(emotionsA: Emotion[], emotionsB: Emotion[]): number {
  return embeddingDist(emotionsToEmbedding(emotionsA), emotionsToEmbedding(emotionsB));
}

// https://github.com/HumeAI/hume-api-examples/blob/main/typescript-streaming-sandbox/lib/hooks/stability.ts#L6
export function getStableEmotions(emotions: Emotion[], stableEmotions: Emotion[]): [Emotion[], boolean] {
  if (stableEmotions.length === 0) {
    return [emotions, true];
  }
  const embeddingDistThreshold = 0.2;
  const dist = emotionDist(emotions, stableEmotions);
  if (dist > embeddingDistThreshold) {
    return [emotions, true];
  }
  return [stableEmotions, false];
}

// https://github.com/HumeAI/hume-api-examples/blob/main/typescript-streaming-sandbox/lib/utilities/embeddingUtilities.ts
function emotionsToScoreMap(emotions: Emotion[]): Map<EmotionName, number> {
  const m = new Map<EmotionName, number>();
  for (let i = 0; i < emotions.length; i++) {
    const emotion = emotions[i];
    m.set(emotion.name, emotion.score);
  }
  return m;
}

function embeddingDist(embeddingA: Embedding, embeddingB: Embedding): number {
  // Not really the distance, actually sum of squared errors
  let s = 0;
  for (let i = 0; i < embeddingA.length; i++) {
    const a = embeddingA[i];
    const b = embeddingB[i];
    s += (b - a) * (b - a);
  }
  return s;
}
