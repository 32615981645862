import { createRoot } from 'react-dom/client';
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn } from '@clerk/clerk-react';

import { GlobalStyle } from './styled';
import './i18';
import { Authenticated } from './Authenticated';
import * as Sentry from '@sentry/react';

if (import.meta.env.ENV !== 'DEVELOPMENT') {
  Sentry.init({
    dsn: 'https://26e16246b2d07ab9130fff59043f5132@o4506670857977856.ingest.sentry.io/4506675857457152',
    integrations: [],
  });
}

createRoot(document.getElementById('root')!).render(
  <Sentry.ErrorBoundary>
    <ClerkProvider publishableKey={import.meta.env.VF_CLERK_PUBLISHABLE_KEY}>
      <GlobalStyle />

      <SignedIn>
        <Authenticated />
      </SignedIn>

      <SignedOut>
        <RedirectToSignIn redirectUrl={import.meta.env.VF_SITE_FE_URL} />
      </SignedOut>
    </ClerkProvider>
  </Sentry.ErrorBoundary>
);
