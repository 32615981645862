import { CustomMessage } from '../custom-message.enum';
import { Trace } from './types';

export const ExitBioModeTrace: Trace = {
  canHandle: ({ type }) => type === 'exit_bio_mode',
  handle: ({ context }) => {
    context.messages.push({ type: CustomMessage.EXIT_BIO_MODE, payload: '' });
    return context;
  },
};
