import { ClassName } from '@/constants';
import { tagFactory } from '@/hocs';
import { styled } from '@/styles';
import { VariantProp } from '@/types';
import { useRef } from 'react';

export const tag = tagFactory(ClassName.IMAGE);

export const DefaultImageBase = styled(tag('img'), {
  maxWidth: 'unset',
  maxHeight: '325px',
  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 4px',
  width: 'auto',
  zIndex: '5',
  margin: '0 auto',
  display: 'block',
  '@media (max-height: 760px)': {
    maxHeight: '200px',
  },
  '@media (max-height: 620px)': {
    maxHeight: '250px',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
  variants: {
    isRounded: {
      true: {
        borderRadius: '$2',
      },
    },
  },
  defaultVariants: {
    isRounded: true,
  },
});

export interface DefaultImageProps extends React.ComponentProps<typeof DefaultImageBase> {
  /**
   * A URL for the image to render.
   */
  image: string | null;

  /**
   * If true, rounds the corners of the image border.
   *
   * @default true
   */
  isRounded?: VariantProp<typeof DefaultImageBase, 'isRounded'>;
}

const DefaultImage: React.FC<DefaultImageProps> = ({ image, ...props }) => {
  //render the image with the given props and src
  return <DefaultImageBase {...props} src={image || undefined} />;
};
/**
 * An image rendered as the `src` of an `<img>`.
 *
 * @see {@link https://voiceflow.github.io/react-chat/?path=/story/core-image--round-corners}
 */
export default Object.assign(DefaultImage, { Base: DefaultImageBase });
