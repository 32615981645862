import React, { useContext } from 'react';
import { ShenaiSDK, MeasurementState } from 'shenai-sdk';
import { styled } from 'styled-components';
import styles from './Bio.module.css';
import { type ShenaiSdkState, getStatusForBioString } from './utils';
import { breakpoints } from '../../styled';
import { HeartIcon } from '../../icons';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contextApp';

interface ShortBioInfoBlockProps {
  sdkState: ShenaiSdkState | undefined;
  shenaiSDK: ShenaiSDK | null;
  isMini?: boolean;
}

const ShortBioInfoBlock: React.FC<ShortBioInfoBlockProps> = ({ sdkState, shenaiSDK, isMini }) => {
  const { i18n } = useTranslation();
  const { direction } = useContext(AppContext)!;
  const getShortBioClass = (val: MeasurementState | undefined) => {
    switch (val) {
      case shenaiSDK?.MeasurementState.NOT_STARTED:
      case shenaiSDK?.MeasurementState.WAITING_FOR_FACE:
      case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_BAD_DEVICE_UNSTABLE:
      case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_SHORT:
        return styles.idle;
      case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_BAD:
      case shenaiSDK?.MeasurementState.FAILED:
        return styles.error;
      case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_GOOD:
      case shenaiSDK?.MeasurementState.FINISHED:
        return styles.success;
      default:
        return '';
    }
  };

  return (
    <BioStatusBlockShort className={isMini ? 'bio-status-mini' : ''}>
      <BioStatusCard className="heartbeat-block">
        <div className={sdkState?.faceState === shenaiSDK?.FaceState.OK ? styles.active : styles.disabled}>
          <div className={direction == 'ltr' ? 'heartbeat-block-number left' : 'heartbeat-block-number'}>
            <span className={sdkState?.faceState === shenaiSDK?.FaceState.OK ? 'active' : 'disabled'}>
              {sdkState?.hr10s ? `${sdkState.hr10s.toFixed(0)}` : ''}
            </span>
            <span className={styles.unit}>{sdkState?.hr10s ? ' bpm' : '...'}</span>
          </div>
        </div>
        <div className={sdkState?.faceState === shenaiSDK?.FaceState.OK ? styles.active : styles.disabled}>
          <div className={styles.heartIcon}>
            <HeartIcon />
          </div>
        </div>
      </BioStatusCard>
      <BioStatusCard className="bio-status-block">
        <div className={styles.outputValue}>
          <span>{sdkState && getStatusForBioString(shenaiSDK, sdkState.measurementState, sdkState.faceState, i18n.language)}</span>
        </div>
        <div>
          <span className={`${styles.circle} ${getShortBioClass(sdkState?.measurementState)}`}></span>
        </div>
      </BioStatusCard>
    </BioStatusBlockShort>
  );
};

export default ShortBioInfoBlock;

export const BioStatusBlockShort = styled.div`
  border-radius: 12px;
  background-color: white;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 50%;
  gap: 15%;
  span {
    color: #777;
    font-size: 14px;
  }
  &.bio-status-mini {
    grid-template-columns: auto 50%;
    gap: 9%;
  }

  @media ${breakpoints.tablet} {
    gap: 8%;
  }
  @media ${breakpoints.mobile} {
    max-width: unset;
    width: 100%;
  }
  @media (max-width: 350px) {
    width: 100%;
    span {
      font-size: 12px;
    }
  }
`;
export const BioStatusCard = styled.div`
  width: 100%;
  display: flex;

  &.heartbeat-block {
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    .heartbeat-block-number {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      font-size: 22px;
      gap: 3px;
      span {
        font-size: 24px;
        line-height: 24px;
      }
    }
    .heartbeat-block-number.left {
      flex-direction: row;
    }
  }
  &.bio-status-block {
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
  @media (max-width: 350px) {
    .heartbeat-block-number {
      span {
        font-size: 10px;
      }
      span:first-child {
        font-size: 16px;
      }
    }
  }
`;
