export enum MessageType {
  TEXT = 'text',
  IMAGE = 'image',
  CARD = 'card',
  CAROUSEL = 'carousel',
  END = 'END',
  AUTOCOMPLETE = 'custom_autocomplete',
  BIO = 'custom_bio',
  UPLOAD_FILE = 'custom_upload_file',
  EXIT_IMAGE_MODE = 'exit_image_mode',
  EXIT_BIO_MODE = 'exit_bio_mode',
}
