import { safeParseJSON } from './helpers';

/**
 * function to get the voiceflow variables
 */
export async function getVoiceflowVariables(userId: string) {
  const res = await fetch(`https://general-runtime.voiceflow.com/state/user/${userId}`, {
    method: 'GET',
    headers: {
      authorization: import.meta.env.VF_DM_API_KEY,
      'content-type': 'application/json',
    },
  });
  const vfData = await res.json();

  return safeParseJSON(vfData.variables);
}

/**
 * function to update the voiceflow variables,
 * its a PATCH request to the voiceflow API, so we only need to send the variables we want to update
 * for example:
 * updateVoiceflowVariables('user_123', { name: 'John' })
 */
export async function updateVoiceflowVariables(userId: string, variables: Record<string, unknown>) {
  const res = await fetch(`https://general-runtime.voiceflow.com/state/user/${userId}/variables`, {
    method: 'PATCH',
    body: JSON.stringify(variables),
    headers: {
      authorization: import.meta.env.VF_DM_API_KEY,
      'content-type': 'application/json',
    },
  });
  const vfData = await res.json();

  return safeParseJSON(vfData.attributes.variables);
}
