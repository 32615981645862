import React, { useState, useEffect, useRef, useContext } from 'react';
import { useUser, useSession, useClerk } from '@clerk/clerk-react';
import type { SessionWithActivitiesResource } from '@clerk/types';

import './ProfileMenu.css';
import { getFullName, getIdentifier, getInitials, currentSessionFirst, getRelativeToNowDateKey } from './utils';
import { ReactComponent as TreeDots } from '../../../icons/threeDots.svg';
import { ReactComponent as SecurityIcon } from '../../../icons/tick-shield.svg';
import { ReactComponent as SignoutIcon } from '../../../icons/signout.svg';
import { ReactComponent as FeedbackIcon } from '../../../icons/chat-alt.svg';
import { ReactComponent as CaretIcon } from '../../../icons/caret.svg';
import { ReactComponent as DeviceLaptopIcon } from '../../../icons/device-laptop.svg';
import { ReactComponent as DeviceMobileIcon } from '../../../icons/device-mobile.svg';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../contextApp';

const ProfileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();
  const { session } = useSession();
  const { signOut } = useClerk();
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [sessionsWithActivities, setSessionsWithActivities] = React.useState<SessionWithActivitiesResource[]>([]);

  const { direction } = useContext(AppContext)!;

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.dir = direction;
    }
  }, [direction]);

  useEffect(() => {
    void user?.getSessions().then((sa) => setSessionsWithActivities(sa));
  }, [user]);

  // when the user clicks outside of the menu, close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [containerRef]);

  const { username, firstName, lastName, imageUrl, id: userId } = user!;
  const generatedName = getFullName({ name: username, firstName, lastName });
  const initials = getInitials({ name: username, firstName, lastName });
  const identifier = user ? getIdentifier(user) : '';
  const title = generatedName || initials || identifier;

  function logoutAccount() {
    try {
      signOut(() => {
        window.location.href = 'https://www.betteryou.tech/';
      });
    } catch (error) {
      console.error('Fail to logout: ', error);
    }
  }

  return (
    <div className="settings-icon-container" ref={containerRef}>
      <div className="profile-icon-wrapper">
        <TreeDots className="settings-img" onClick={() => setIsOpen(!isOpen)} />
      </div>
      {isOpen && (
        <div className="settings-dropdown-options">
          <div className="user-identifier">
            <img title={title} alt={title} src={imageUrl || ''} width="100%" height="100%" className="avatar" />
            <div className="identifier">{user && getIdentifier(user)}</div>
          </div>

          <div className="action-button" onClick={logoutAccount}>
            <SignoutIcon className="icon" />
            {t('profileMenu.logout')}
          </div>

          <a className="action-button" href={`https://8ip5mdxynd3.typeform.com/to/k6MmW80a#user_id=${userId}`} target="_blank">
            <FeedbackIcon className="icon" />
            {t('profileMenu.feedback')}
          </a>

          <a className="action-button" href={`${import.meta.env.VF_SITE_DASHBOARD_URL}`}>
            <FeedbackIcon className="icon" />
            {t('profileMenu.dashboard')}
          </a>
        </div>
      )}
    </div>
  );
};

const DeviceAccordion = (props: { session: SessionWithActivitiesResource }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { session } = useSession();
  const isCurrent = session?.id === props.session.id;

  const revoke = async () => {
    if (isCurrent || !props.session) {
      return;
    }
    return props.session.revoke();
  };

  return (
    <div className="accordion" onClick={() => setIsOpen(!isOpen)}>
      <DeviceInfo session={props.session} isOpen={isOpen} />
      {isOpen && isCurrent && (
        <div className="accordion-content">
          <div className="small-medium">Current device</div>
          <div className="small-regular">This is the device you are currently using</div>
        </div>
      )}
      {isOpen && !isCurrent && (
        <div className="accordion-content">
          <div className="small-medium">Sign out</div>
          <div className="small-regular">Sign out from your account on this device</div>
          <button className="button-small danger-outline" onClick={revoke}>
            Sign out of device
          </button>
        </div>
      )}
    </div>
  );
};

const DeviceInfo = (props: { session: SessionWithActivitiesResource; isOpen: boolean }) => {
  const { session } = useSession();
  const isCurrent = session?.id === props.session.id;
  const isCurrentlyImpersonating = !!session?.actor;
  const isImpersonationSession = !!props.session.actor;
  const { city, country, browserName, browserVersion, deviceType, ipAddress, isMobile } = props.session.latestActivity;
  const title = deviceType ? deviceType : isMobile ? 'Mobile device' : 'Desktop device';
  const browser = `${browserName || ''} ${browserVersion || ''}`.trim() || 'Web browser';
  const location = [city || '', country || ''].filter(Boolean).join(', ').trim() || null;

  return (
    <div className={`device-info ${isCurrent ? 'current' : ''}`}>
      <div className="flex-row">{isMobile ? <DeviceMobileIcon className="device-image" /> : <DeviceLaptopIcon className="device-image" />}</div>
      <div className="flex-column justify-center">
        <div className="device-title">
          <div className="small-medium">{title}</div>
          {isCurrent && <div className={`badge ${isCurrentlyImpersonating ? 'danger' : 'primary'}`}>This Device</div>}
          {isCurrentlyImpersonating && !isImpersonationSession && <div className={`badge`}>User Device</div>}
          {!isCurrent && isImpersonationSession && <div className={`badge danger`}>Other Impersonator Device</div>}
        </div>
        <div className="small-regular">{browser}</div>
        <div className="small-regular">
          {ipAddress} ({location})
        </div>
        <div className="small-regular">{getRelativeToNowDateKey(props.session.lastActiveAt)}</div>
      </div>
      <div>
        <CaretIcon className={`icon ${props.isOpen ? 'flip' : ''}`} />
      </div>
    </div>
  );
};

const DeleteAccordion = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();

  function deleteAccount() {
    try {
      user?.delete().then(() => {
        window.location.href = '/';
      });
    } catch (error) {
      console.error('Fail to delete account: ', error);
    }
  }

  return (
    <div className="accordion" onClick={() => setIsOpen(!isOpen)}>
      <div className="small-section flex-row">
        <div>
          <div className="small-medium">Delete Account</div>
          <div className="small-regular">Delete your account and all its associated data</div>
        </div>
        <div>
          <CaretIcon className={`icon ${isOpen ? 'flip' : ''}`} />
        </div>
      </div>
      {isOpen && (
        <div className="small-section">
          <button className="button-small danger-outline" onClick={deleteAccount}>
            Delete Account
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;
