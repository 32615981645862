export enum CustomMessage {
  CALENDAR = 'custom_calendar',
  VIDEO = 'custom_video',
  VIDEO_GENERATED = 'custom_generated_video',
  STREAMED_RESPONSE = 'custom_streamed_response',
  PLUGIN = 'custom_plugin',
  BIO = 'custom_bio',
  VIDEO_GEN_TRIGGER = 'custom_vid_gen',
  AUTOCOMPLETE = 'custom_autocomplete',
  WAITFORVIDEO = 'custom_video_waiting',
  EXIT_IMAGE_MODE = 'exit_image_mode',
  EXIT_BIO_MODE = 'exit_bio_mode',
  SEND_FRAME = 'send_frame',
  UPLOAD_FILE = 'custom_upload_file',
}

// cutom BetterYou intent codes
export const BY_BIO_MODE = '[[bio_mode]]';
export const BY_TXT_MODE = '[[txt_mode]]';
export const BY_IMG_MODE = '[[img_mode]]';
export const BY_VID_MODE = '[[vid_mode]]';
