import { useEffect } from 'react';

interface IUseTabFocusProps {
  onFocus: () => void;
  onUnfocus: () => void;
  turnId: string;
}

export const useTabFocus = (props: IUseTabFocusProps) => {
  const { onFocus, onUnfocus, turnId } = props;

  useEffect(() => {
    document.onvisibilitychange = () => {
      if (document.visibilityState === 'visible') {
        onFocus();
      } else {
        onUnfocus();
      }
    };
  }, [turnId]);
};
