import { CustomMessage } from '../custom-message.enum';
import { Trace } from './types';

export const VideoGeneratedTrace: Trace = {
  canHandle: ({ payload }) => payload?.name === 'video_generated_component',
  handle: ({ context }, trace) => {
    const videoPayload = trace?.payload?.body ? JSON.parse(trace?.payload?.body) : '';
    context.messages.push({ type: CustomMessage.VIDEO_GENERATED, payload: videoPayload });
    return context;
  },
};
