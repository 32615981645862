import { CustomMessage } from '../custom-message.enum';
import { Trace } from './types';

export const SendFrameTrace: Trace = {
  canHandle: ({ type }) => type === 'send_frame',
  handle: ({ context }) => {
    context.messages.push({ type: CustomMessage.SEND_FRAME, payload: '' });
    return context;
  },
};
