import { MutableRefObject } from 'react';

import { getEmotionDescriptor } from '../utils/hume';

export const initGetCameraFrameEmotions = (
  canvasRef: MutableRefObject<HTMLCanvasElement | null>,
  socket: WebSocket | null,
  isFaceOk: boolean = true,
  delay: number = 3000
): ReturnType<typeof setInterval> | null => {
  if (!canvasRef.current) {
    return null;
  }

  const interval = setInterval(() => {
    if (!socket || socket.readyState !== socket.OPEN || !isFaceOk || document.hidden) return;

    const frameBase64 = canvasRef.current?.toDataURL('image/jpeg') || '';
    try {
      socket.send(
        JSON.stringify({
          models: {
            face: {},
          },
          data: frameBase64,
        })
      );
    } catch (e) {
      console.log(e);
    }
  }, delay);

  return interval;
};

export const getTopEmotions = (emotions: unknown, topEmotionsNum: number = 3) => {
  if (!Array.isArray(emotions)) {
    return [];
  }

  return [...emotions].sort((a, b) => b.score - a.score).slice(0, topEmotionsNum);
};

// https://github.com/HumeAI/hume-api-examples/blob/main/typescript-streaming-sandbox/components/widgets/Descriptor.tsx#L18
export const createDescription = (stableEmotions: unknown): string => {
  if (!Array.isArray(stableEmotions)) {
    return '';
  }

  const emotions = [...stableEmotions];
  emotions.sort((a, b) => (a.score < b.score ? 1 : -1));
  if (emotions.length < 2) return '';

  const primaryEmotion = emotions[0];
  let secondaryEmotion = emotions[1];
  let secondaryDescriptor = '';
  for (let i = 1; i < emotions.length; i++) {
    const emotion = emotions[i];
    const descriptor = getEmotionDescriptor(emotion.name);
    if (descriptor) {
      secondaryDescriptor = descriptor;
      secondaryEmotion = emotion;
      break;
    }
  }

  const emotionDistThreshold = 0.1;
  if (Math.abs(primaryEmotion.score - secondaryEmotion.score) > emotionDistThreshold) {
    return primaryEmotion.name;
  }
  return `${secondaryDescriptor} ${primaryEmotion.name}`;
};
