import { serializeToMarkdown } from '@voiceflow/slate-serializer/markdown';

import { MessageProps } from '../types';
import { COMMA_DELAY, DEFAULT_MESSAGE_DELAY, DEFAULT_SENTENCE_DELAY, DEFAULT_WORD_DELAY } from '../constants';

export const splitSentencesRegExp = /[^.!?][^.!?\n]*(?:[.!?\n](?!['"“\.{3}]?\s|$)[^!?]\n*)*[.!?\n?\r]?['*"“\.{3}]?(?=\s|['"*“\.{3}]|$)/gim;
export const isSpecialSentenceRegExp = /^(?:&nbsp;|\r|\n)$/gim;
export const wordToPauseRegExp = /.*[;,]$/gim;

export const splitTextBySentences = (text: string): string[] => text?.match(splitSentencesRegExp) || [];

export const splitTextBySentencesAndWords = (text: string): string[][] => {
  const sentences = splitTextBySentences(text);
  return sentences.map((sentence) => sentence.split(' '));
};

export const splitMessageBySentencesAndWords = (message: MessageProps): string[][] => {
  const text = serializeToMarkdown(message);
  return splitTextBySentencesAndWords(text);
};

export const getMessageDelay = (message: MessageProps): number => {
  const sentences: string[][] = splitMessageBySentencesAndWords(message.text).filter((words) => !isSpecialSentenceRegExp.test(words.join(' ')));
  const totalWordsQty = sentences.reduce((acc, sentence) => acc + sentence.length, 0);

  let additionalDelay = 0;
  if (totalWordsQty <= 10) {
    additionalDelay = 1000;
  } else if (totalWordsQty > 10) {
    additionalDelay = 2000;
  }

  return DEFAULT_SENTENCE_DELAY * sentences.length + DEFAULT_WORD_DELAY * totalWordsQty + COMMA_DELAY * sentences.length + additionalDelay;
};

export const getMessageAudio = async (text: string) => {
  const voiceId = 'GeaDt3tFWdX6KNw5Dkmf';
  const modelId = 'eleven_turbo_v2';
  const apiKey = import.meta.env.VF_ELEVEN_LABS_API_KEY;
  const voiceSettings = {
    stability: 0.75,
    similarity_boost: 0.75,
  };

  const baseUrl = 'https://api.elevenlabs.io/v1/text-to-speech';
  const headers = {
    'Content-Type': 'application/json',
    'xi-api-key': apiKey,
  };

  const requestBody = {
    text,
    model_id: modelId,
    voice_settings: voiceSettings,
  };

  try {
    const url = `${baseUrl}/${voiceId}`;
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(requestBody),
    });

    if (response.status === 200) {
      const resBlob = await response.blob();
      return new Audio(URL.createObjectURL(resBlob));
    } else {
      console.log('Error: Unable to stream audio.');
    }
  } catch (error) {
    console.log('error:', error);
  }
};
