import { baseUrl } from '../api/base';

type VideoBody = {
  video: {
    user_id: string;
    address: string;
  };
};

export const createVideo = async (body: VideoBody): Promise<Response> => {
  const url = `${baseUrl}/video`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response;
};
