import { CustomMessage } from '../custom-message.enum';
import { Trace } from './types';

export const WaitForVideoTrace: Trace = {
  canHandle: ({ payload }) => payload?.name === 'wait_for_video',
  handle: ({ context }, trace) => {
    const videoPayload = trace?.payload?.body ? JSON.parse(trace?.payload?.body) : '';
    context.messages.push({ type: CustomMessage.WAITFORVIDEO, payload: videoPayload });
    return context;
  },
};
