import { ClassName } from '@/constants';
import { tagFactory } from '@/hocs';
import { styled } from '@/styles';

export const tag = tagFactory(ClassName.MESSAGE);

export const Container = styled(tag('div'), {
  typo: {},
  overflowWrap: 'anywhere',
  backgroundColor: 'transparent',
  fontWeight: 'unset',
  fontSize: '20px',
  lineHeight: '28px',
  '@media (max-width: 940px)': {
    fontSize: '16px',
    width: '100%',
  },
  '@media (max-width: 520px)': {
    fontSize: '14px',
  },
});
