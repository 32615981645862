import { useCallback, useContext, useEffect, useState } from 'react';
import { useRuntime } from '../../packages/react-chat';

import FileUploader from '../components/fileUploader/FileUploader';
import { useUser } from '@clerk/clerk-react';

import { blobToBase64 } from '../utils/blob';
import { updateVoiceflowVariables } from '../utils/voiceflowVariables';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  &.uploaded {
    display: none;
  }
`;
interface ISendMessageProps {
  runtime: ReturnType<typeof useRuntime>;
}

export const UploadFileMessage = (props: ISendMessageProps) => {
  const BASE_URL = `${import.meta.env.VF_SITE_BE_URL}/api`;
  const { user } = useUser();
  const { runtime } = props;
  const userId = runtime.session.userID;
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isUploaded, setUploaded] = useState(false);

  const onFileChange = useCallback((files: File[]) => {
    setSelectedFiles(files);
  }, []);

  const onFileUpload = async () => {
    setUploaded(true);
    const formData: any = new FormData();

    selectedFiles.forEach((file, index) => {
      formData.append(`files`, file, `uploaded_img_${file.name}`);
    });

    const url = `${BASE_URL}/uploaded_image/${userId}`;

    if (selectedFiles) {
      try {
        const res = await fetch(url, {
          method: 'POST',
          body: formData,
        });
        const uploadedUrls = await res.json();
        user && (await updateVoiceflowVariables(userId, { upload_image: JSON.stringify(uploadedUrls.image_url) }));

        runtime.interact({ type: 'upload_done', payload: uploadedUrls.image_url });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onCancel = () => {
    setUploaded(true);
    runtime.interact({ type: 'upload_fail', payload: '' });
  };

  return (
    <Wrapper className={isUploaded ? 'uploaded' : ''}>
      <FileUploader onFileChange={onFileChange} onFileUpload={onFileUpload} onCancel={onCancel} />
    </Wrapper>
  );
};
