import { keyframes, styled } from 'styled-components';

export const DropdownWrapper = styled.div`
display; flex;
padding: 10px;
width: 100%;
background: transparent;
max-width: 150px;
border-radius: 12px;
cursor: pointer;
`;
export const SelectedItem = styled.div`
  display: flex;

  gap: 5px;
  align-items: center;
  span {
    display: inline-block;
  }
  span.language-icon {
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  span.arrow-icon {
    width: 18px;
    height: 18px;
    transform: rotate(90deg);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    -ms-transition: -ms-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    svg {
      width: 18px;
      height: 18px;
    }
  }
  .dropdown-visible {
    transform: rotate(270deg) !important;
  }
`;

export const fadeInFromNone = keyframes`
 
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: flex;
        opacity: 0;
    }

    100% {
        display: flex;
        opacity: 1;
    }

`;
export const fadeToNone = keyframes`
 
    0% {
        display: flex;
        opacity: 1;
    }

    100% {
        display: none;
        opacity: 0;
    }

`;

export const DropdownItems = styled.ul`
  list-style-type: none;
  margin: 0;
  display: none;
  flex-direction: column;
  position: absolute;
  &.visible {
    display: flex;

    animation: ${fadeInFromNone} 0.3s ease-out;
  }
  &.hidden {
    display: none;
    animation: ${fadeToNone} 0.3s ease-out;
  }
`;
export const DropdownItem = styled.li`
  display: flex;
  cursor: pointer;
`;
