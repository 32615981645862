import React, { memo, useRef, useState } from 'react';

import AssistantInfo, { AssistantInfoProps } from '@/components/AssistantInfo';
import Footer, { FooterProps } from '@/components/Footer';
import Loader from '@/components/Loader';
import { AutoScrollProvider } from '@/contexts';
import { Nullish } from '@/types';

import { useTimestamp } from './hooks';
import { Container, Dialog, Overlay, SessionTime, Spacer, Status } from './styled';
import { useTranslation } from 'react-i18next';

export interface ChatProps extends AssistantInfoProps, FooterProps, React.PropsWithChildren<unknown | { children?: any }> {
  /**
   * A short description of the assistant to help frame the conversation.
   */
  description: string;

  /**
   * If true, shows a loading indicator.
   */
  isLoading: boolean;

  /**
   * A unix timestamp indicating the start of the conversation.
   */
  startTime?: Nullish<number>;

  /**
   * If true, a Voiceflow watermark is added to the footer.
   */
  withWatermark: boolean;

  /**
   * A callback that is executed when the chat widget is minimized.
   */
  onMinimize?: React.MouseEventHandler<HTMLButtonElement>;

  /**
   * A callback that is executed when the conversation ends.
   */
  onEnd?: React.MouseEventHandler<HTMLButtonElement>;

  isInputDisabled: boolean;
  children: false | Element;
  isCurrentTurnTextOnly?: boolean;
}

const Chat: React.FC<ChatProps> = ({
  hasEnded,
  title,
  avatar,
  description,
  startTime,
  isLoading,
  withWatermark,
  onEnd,
  onStart,
  onSend,
  isInputDisabled,
  children,
  isCurrentTurnTextOnly = false,
}) => {
  const timestamp = useTimestamp(startTime);
  const dialogRef = useRef<HTMLElement>(null);
  const [hasAlert, setAlert] = useState(false);
  const { t } = useTranslation();
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (hasEnded) {
      onEnd?.(event);
    } else {
      setAlert(true);
    }
  };
  const handleResume = (): void => setAlert(false);

  if (isLoading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container className="rtl-chat-container" isCurrentTurnTextOnly={isCurrentTurnTextOnly}>
      <Dialog className="rtl-chat-dialog" ref={dialogRef}>
        <AutoScrollProvider target={dialogRef}>
          <AssistantInfo title={title} avatar={avatar} description={description} />
          <Spacer />
          {!!timestamp && <SessionTime>{timestamp}</SessionTime>}
          {children}
          {hasEnded && (
            <Status>
              <span>
                {' '}
                {t('endMessage.first')}
                <a href="/"> {t('endMessage.link')}</a>.
              </span>
            </Status>
          )}
        </AutoScrollProvider>
      </Dialog>
      <Footer withWatermark={withWatermark} hasEnded={hasEnded} onStart={onStart} onSend={onSend} isInputDisabled={isInputDisabled} />
      <Overlay />
    </Container>
  );
};

/**
 * A full chat dialog with header, footer, overlay and auto-scrolling content.
 *
 * @see {@link https://voiceflow.github.io/react-chat/?path=/story/templates-chat--empty}
 */
export default Object.assign(memo(Chat), {
  Container,
  Dialog,
  Overlay,
  Spacer,
  Status,
});
