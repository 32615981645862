import { ClassName } from '@/constants';
import { tagFactory } from '@/hocs';
import { styled } from '@/styles';

const tag = tagFactory(ClassName.USER_RESPONSE);

export const Debug = styled(tag('aside', 'debug'), {
  typo: { size: '12px', height: '17px' },
  color: '$darkGrey',
  marginTop: '$2',
});

export const Container = styled(tag('div'), {
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  alignItems: 'flex-end',
  width: '620px',
  [`&.rtl-container-response`]: {
    '@media (max-width: 768px)': {
      width: 'unset',
    },
  },
});

export const Row = styled(tag('div', 'row'), {
  display: 'flex',
  width: '100%',
  alignItems: 'left',
  [`&.rtl-row-response`]: {
    '@media (max-width: 768px)': {
      width: 'unset',
      justifyContent: 'start',
      alignItems: 'unset',
    },
  },
});
