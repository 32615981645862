import React, { useContext, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { breakpoints } from '../../styled';
import { AppContext } from '../../contextApp';

const isRTL = import.meta.env.VF_DM_API_KEY === import.meta.env.VF_DM_PTSD_API_KEY;

const rotate = () => keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const move = (props) => keyframes`
  0%{
    transform: translateY(0) scale(1);
  }
  50%{
    transform: translateY(${props.translateto}${props.unit}) scale(0.8);
  }
  100%{
    transform: translateY(0) scale(1);
  }
`;

const getBalls = ({ countBalls, size, unit }) => {
  const balls = [];
  const center = size / 4;
  const ballsTranslatePositions = [-center, center];
  for (let i = 0; i < countBalls; i++) {
    balls.push(
      <Ball
        size={size}
        x={size / 2 - size / 6}
        y={size / 2 - size / 6}
        key={i.toString()}
        translateto={ballsTranslatePositions[i]}
        index={i}
        unit={unit}
      />
    );
  }
  return balls;
};

interface GooSpinnerProps {
  size: number;
  unit: string;
  loading: boolean;
}

export const GooSpinner: React.FC<GooSpinnerProps> = ({ size, loading, unit }) => {
  const countBalls = 2;

  const { direction } = useContext(AppContext)!;

  return (
    loading && (
      <Wrapper className={direction == 'ltr' ? 'goo-spinner left' : 'goo-spinner'} size={size} unit={unit} direction>
        <BallsWrapper size={size} unit={unit}>
          {getBalls({ countBalls, size, unit })}
        </BallsWrapper>
      </Wrapper>
    )
  );
};
const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0.3;
  }
  25%, 75% {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  width: ${(props) => `${props.size}${props.unit}`};
  height: ${(props) => `${props.size}${props.unit}`};
  filter: url('#goo');
  position: absolute;
  right: -1rem;
  animation: ${fadeInOut} 1.5s linear infinite;
  &.left {
    right: unset;
    left: -1rem;
  }

  @media ${breakpoints.tablet} {
    right: 0;
  }
`;

const BallsWrapper = styled.div`
  position: relative;
  width: ${(props) => `${props.size}${props.unit}`};
  height: ${(props) => `${props.size}${props.unit}`};
  animation: ${rotate} 1.7s linear infinite;
`;
// div: nth - child(1) {
//   background: linear - gradient(#bd12ac, #9248c9);
// }
// div: nth - child(2) {
//   background: linear - gradient(#9248c9, #677ee6);
// }

const colorFade = keyframes`
0 %, 50 % {
  background: #677EE6;
};
50.1%, 100% {
  background: #BD12AC; 
};
`;

const Ball = styled.div`
  position: absolute;
  top: ${(props) => `${props.y}${props.unit}`};
  left: ${(props) => `${props.x}${props.unit}`};
  width: ${(props) => `${props.size / 3}${props.unit}`};
  height: ${(props) => `${props.size / 3}${props.unit}`};
  border-radius: 50%;
  background: #677ee6;
  animation: ${move} 1.5s ease-in-out infinite, ${colorFade} 3s linear infinite;
`;
