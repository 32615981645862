import { CustomMessage } from '../custom-message.enum';
import { Trace } from './types';

export const AutocompleteTrace: Trace = {
  canHandle: ({ payload }) => payload?.name === 'get_address',
  handle: ({ context }, trace) => {
    context.messages.push({ type: CustomMessage.AUTOCOMPLETE, payload: trace.payload.body });
    return context;
  },
};
