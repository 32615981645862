import { useRef, useEffect, useState } from 'react';

interface IUseSocketOptions {
  onMessage: (data: string) => void;
}

export const useSocket = (url: string, options: IUseSocketOptions, isFaceOk: boolean = false): [WebSocket | null, boolean] => {
  const { onMessage } = options;
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const socketRef = useRef<WebSocket | null>(null);

  const initializeWebSocket = () => {
    const newSocket = new WebSocket(url);
    setSocket(newSocket);
    socketRef.current = newSocket;

    newSocket.onopen = () => {
      console.log('WebSocket connection opened');
    };

    newSocket.onmessage = (event) => {
      onMessage(event.data);
    };

    newSocket.onclose = (event) => {
      console.log('WebSocket connection closed', event.code, event.reason);

      if ([1000, 1006].includes(event.code)) {
        setTimeout(() => {
          initializeWebSocket();
        }, 0);
      }
    };
  };

  useEffect(() => {
    if (!socketRef.current) return;
    socketRef.current.onmessage = (event) => {
      onMessage(event.data);
    };
  }, [options.onMessage, socketRef.current]);

  useEffect(() => {
    if (isFaceOk) {
      initializeWebSocket();
    }

    return () => {
      // socketRef.current?.close(1000);
    };
  }, [url, isFaceOk]);

  const isConnected = !!socketRef.current && socketRef.current.readyState === socketRef.current.OPEN;
  return [socketRef.current, isConnected];
};
