import Message from '@/components/Message';
import { ClassName } from '@/constants';
import { tagFactory } from '@/hocs';
import { fadeIn, styled } from '@/styles';

const tag = tagFactory(ClassName.SYSTEM_RESPONSE);

export const Actions = styled(tag('div', 'actions'), {
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: 8,
  padding: '0 $5 0 54px',
});

export const Controls = styled(tag('span', 'controls'), {
  position: 'relative',
});

export const Container = styled(tag('div'), {
  display: 'flex',
  justifyContent: 'center',
  variants: {
    withImage: {
      true: {},
    },
    scrollable: {
      true: {
        overflowX: 'scroll',
        flexShrink: 0,
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',

        [`&::-webkit-scrollbar`]: {
          display: 'none',
        },
      },
    },
    center: {
      true: {
        alignItems: 'left',
      },
    },
  },
});

export const MessageContainer = styled(tag(Container, 'message'), {
  position: 'relative',
  color: '#000',
  '&:last-child': {
    color: '#333',
  },
  variants: {
    isFadeOut: {
      true: {
        transition: 'opacity .6s ease-in-out, margin-top 1s ease-in-out, filter .6s ease-in-out',
        opacity: 0,
        filter: 'blur(5px)',
      },
    },
  },
});

export const IndicatorContainer = styled(tag(Container, 'indicator'));

export const List = styled(tag('div', 'list'), {
  display: 'block',
  flexDirection: 'column',
  flexShrink: 0,
  [`& ${Message.Chat}`]: {
    display: 'contents',
  },
  [`& ${Message.Container}`]: {
    marginBottom: '$1',

    '&:last-of-type': {
      marginBottom: '0',
    },
  },
  [`&.rtl-response-list`]: {
    flexShrink: ' unset',
    width: '100%',
  },
});
export const MessageButtons = styled(tag('div'), {
  display: 'flex',
  gap: '10px',
  marginTop: '1%',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    alignSelf: 'center',
  },

  button: {
    borderRadius: '12px',
    backgroundColor: 'white',
    padding: '8px 16px',
    border: 'none',
    fontSize: '20px',
    lineHeight: '20px',
    fontWeight: '400',
    textDecoration: 'none',
    fontFamily: 'var(--fonts-default)',
    cursor: 'pointer',
    color: '#777',
    animation: `${fadeIn} 0.6s linear`,
    animationFillMode: 'both',

    '@media (max-width: 940px)': {
      fontSize: '16px',
    },
    '@media (max-width: 768px)': {
      minWidth: '180px',
      width: '100%',
    },
    '@media (max-width: 520px)': {
      fontSize: '14px',
    },
  },
});
