import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Thumb, ThumbInner, ThumbImg, ThumbsContainer, TextContent } from './styled';
import { TrashIcon } from '../../icons';

interface CustomFile extends File {
  preview: any;
}
interface CDropzoneProps {
  onChange: (x: CustomFile[]) => void;
  setIsDisableUpload: (x: boolean) => void;
}
const CDropzone = ({ onChange, setIsDisableUpload }: CDropzoneProps) => {
  const [files, setFiles] = useState<CustomFile[]>([]);
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    maxSize: 2097152,
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    onChange(files);
    setIsDisableUpload(files.length == 0);
  }, [files]);

  const handleThumbClick = (i: number) => {
    const filteredFiles = files.filter((el) => el !== files[i]);
    setFiles(filteredFiles);
  };

  const thumbs = files.map((file, index) => (
    <Thumb key={file.name} onClick={() => handleThumbClick(index)}>
      <div className="overlay">
        <TrashIcon />
      </div>
      <ThumbInner>
        <ThumbImg
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </ThumbInner>
    </Thumb>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <div className="opacity-block"></div>
        <input {...getInputProps()} />
        <TextContent>
          <span className="text">{t('dragAndDrop.first')}</span>
          <span className="description">{t('dragAndDrop.second')}</span>
        </TextContent>
      </div>
      <ThumbsContainer>{thumbs}</ThumbsContainer>
    </section>
  );
};

export default CDropzone;
