import React, { useState } from 'react';

import { thumbsUp as ThumbsUp } from '@/assets/svg';
import { FeedbackName } from '@/hooks';
import { TurnProps } from '@/types';

import CustomFeedback from './CustomFeedback';
import { Button, ButtonsContainer, Container, Description } from './styled';

export interface FeedbackProps extends React.PropsWithChildren {
  /**
   * Alternative question to ask the user
   *
   * @default 'Was this helpful?'
   */
  question?: string;

  onClick: (feedback: FeedbackName, feedbackData: any) => void;
  turn: TurnProps;
}

const Feedback: React.FC<FeedbackProps> = ({ question, onClick, turn, ...props }) => {
  const [active, setActive] = React.useState<FeedbackName | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPositive, setIsPositive] = useState<boolean>(false);

  const [feedback, setFeedback] = useState<FeedbackName | null>(null);

  const handleClick = (feedback: FeedbackName, mood: 'positive' | 'negative') => {
    if (feedback === active) {
      setActive(null);
      return;
    }

    setIsOpen(!isOpen);
    setFeedback(feedback);
    if (mood === 'positive') {
      setIsPositive(true);
    } else setIsPositive(false);
    setActive(feedback);
  };

  return (
    <Container {...props}>
      <div style={{ display: 'flex', gap: '10px', marginRight: '10px' }}>
        {!!question && <Description>{question}</Description>}

        <ButtonsContainer>
          <Button orientation="positive" active={active === FeedbackName.POSITIVE} onClick={() => handleClick(FeedbackName.POSITIVE, 'positive')}>
            <ThumbsUp width="24px" height="24px" />
          </Button>
          <Button orientation="negative" active={active === FeedbackName.NEGATIVE} onClick={() => handleClick(FeedbackName.NEGATIVE, 'negative')}>
            <ThumbsUp width="24px" height="24px" />
          </Button>
        </ButtonsContainer>
      </div>

      {isOpen && (
        <div className="feedback-wrapper " style={{ height: '280px' }}>
          <CustomFeedback setIsOpen={setIsOpen} isPositive={isPositive} feedback={feedback} onFeedbackSubmit={onClick} turn={turn} />
        </div>
      )}
    </Container>
  );
};

export default Feedback;
