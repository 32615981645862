import Icon from '@/components/Icon';
import Input from '@/components/Input';
import { inputFocusStyles, inputStyles } from '@/components/Input/styled';
import { ClassName } from '@/constants';
import { tagFactory } from '@/hocs';
import { styled } from '@/styles';

const tag = tagFactory(ClassName.CHAT_INPUT);

export const ButtonContainer = styled(tag('label', 'button'), {
  ...inputStyles,

  display: 'none',

  variants: {
    withContent: {
      true: {},
    },
  },
});

export const Container = styled(tag('div'), {
  alignSelf: 'left',
  fontSize: '20px',
  textAlign: 'left',
  width: '620px',
  [`& ${Input.Container}`]: {
    width: '60vw',
    margin: 0,
    borderRightWidth: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    paddingRight: 0,
    paddingLeft: 0,
    boxShadow: 'none',
  },

  [`& ${ButtonContainer}`]: {
    borderLeftWidth: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },

  [`& ${Icon.Frame}`]: {
    color: '$white',
  },

  [`& ${Input.Container}:focus`]: {
    borderRightWidth: 0,
  },

  [`& ${Input.Container}:focus + ${ButtonContainer}`]: {
    ...inputFocusStyles,
    borderLeftWidth: 0,
  },

  [` &.rtl-chat-container`]: {
    width: '100%',
    textarea: {
      color: '#333',
      minWidth: 'unset',
      width: '100%',
      resize: 'none',
      lineHeight: '28px',

      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      '&::placeholder': {
        fontSize: '20px',
        color: '#777',
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '@media (max-width: 940px)': {
      textarea: {
        fontSize: '16px',
        '&::placeholder': {
          fontSize: '16px',
        },
      },
    },
    '@media (max-width: 768px)': {
      teatAlign: 'right',
      width: '100%',
      textarea: {
        width: '100% !important',
        minWidth: 'unset !important',
      },
    },
    '@media (max-width: 520px)': {
      textarea: {
        fontSize: '14px',
        '&::placeholder': {
          fontSize: '14px',
        },
      },
    },
  },
});
