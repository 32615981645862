import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components';

import { close as CloseIcon, thumbsUp as ThumbsUpIcon } from '@/assets/svg';
import { FeedbackName } from '@/hooks';
import { TurnProps } from '@/types';
import { AppContext } from '../../../../../src/contextApp';
import { EventLogType } from '../../../../../src/models/eventLog';
import { useTranslation } from 'react-i18next';

interface CustomFeedbackProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPositive: boolean;
  feedback: FeedbackName;
  onFeedbackSubmit: (feedback: FeedbackName, feedbackData: any) => void;
  turn: TurnProps;
}

const CustomFeedback: React.FC<CustomFeedbackProps> = ({ setIsOpen, isPositive, feedback, onFeedbackSubmit, turn }) => {
  const defaultValues = {
    feedbackText: '',
    harmful: false,
    untrue: false,
    unhelpful: false,
  };
  const [values, setValues] = useState(defaultValues);
  const { addEventLog } = useContext(AppContext);
  const { t } = useTranslation();

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleFeedBackTextareaChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      feedbackText: event.target.value,
    }));
  };

  const handleCheckboxToggle = ({ target }) => setValues((s) => ({ ...s, [target.name]: !s[target.name] }));
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsOpen(false);
    setValues(defaultValues);
    onFeedbackSubmit(feedback, values);

    const turnId = turn.id;
    const children = (turn as any).messages?.[0].text?.[0]?.children || [];
    addEventLog([
      {
        type: EventLogType.FEEDBACK,
        data: {
          turnId,
          children,
          isPositive,
          feedback,
          feedbackData: values,
        },
      },
    ]);
  };

  const feedbackBlockdata = {
    positive: {
      transformIcon: 'none',
      placeholder: t('feedback.placeholder1'),
      iconBgColor: '#90c575',
    },
    negative: {
      transformIcon: 'rotate(180deg)',
      placeholder: t('feedback.placeholder2'),
      iconBgColor: '#df6569',
    },
  };
  const feedbackViewData = isPositive ? feedbackBlockdata.positive : feedbackBlockdata.negative;
  const containerRef = useRef<HTMLDivElement>(null);

  // when the user clicks outside of the menu, close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [containerRef]);
  return (
    <CustomFeedbackwrapper ref={containerRef}>
      <CustomFeedbackTopBlock>
        <TitleWrapper>
          <IconWrapper style={{ backgroundColor: feedbackViewData.iconBgColor, transform: feedbackViewData.transformIcon }}>
            <ThumbsUpIcon />
          </IconWrapper>
          <Title> {t('feedback.title')}</Title>
        </TitleWrapper>
        <CustomFeedbackCloseBtn>
          <CloseIcon onClick={handleClose} />
        </CustomFeedbackCloseBtn>
      </CustomFeedbackTopBlock>
      <CustomFeedbackBottomBlock>
        <CustomFeedbackForm onSubmit={handleSubmit}>
          <CustomFeedbackTextArea
            value={values.feedbackText}
            onChange={handleFeedBackTextareaChange}
            name="feedback-textarea"
            placeholder={feedbackViewData.placeholder}
          />
          {!isPositive && (
            <CheckboxesContainer>
              <CheckboxItem>
                <input
                  id="feedback-checkbox-0"
                  className="checkbox-custom"
                  name="harmful"
                  type="checkbox"
                  onChange={handleCheckboxToggle}
                  checked={values.harmful}
                />
                <label htmlFor="feedback-checkbox-0" className="checkbox-custom-label">
                  {t('feedback.checkbox1')}
                </label>
              </CheckboxItem>
              <CheckboxItem>
                <input
                  id="feedback-checkbox-1"
                  className="checkbox-custom"
                  name="untrue"
                  type="checkbox"
                  onChange={handleCheckboxToggle}
                  checked={values.untrue}
                />
                <label htmlFor="feedback-checkbox-1" className="checkbox-custom-label">
                  {t('feedback.checkbox2')}
                </label>
              </CheckboxItem>
              <CheckboxItem>
                <input
                  id="feedback-checkbox-2"
                  className="checkbox-custom"
                  name="unhelpful"
                  type="checkbox"
                  onChange={handleCheckboxToggle}
                  checked={values.unhelpful}
                />
                <label htmlFor="feedback-checkbox-2" className="checkbox-custom-label">
                  {t('feedback.checkbox3')}
                </label>
              </CheckboxItem>
            </CheckboxesContainer>
          )}
          <CustomSubmitFeedbackBtn type="submit">{t('feedback.button')}</CustomSubmitFeedbackBtn>
        </CustomFeedbackForm>
      </CustomFeedbackBottomBlock>
    </CustomFeedbackwrapper>
  );
};
export default CustomFeedback;

export const CustomFeedbackwrapper = styled.div`
  max-width: 350px;
  font-family: 'Varela Round', sans-serif;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  position: absolute;
  right: 0;
  top: 23%;
  z-index: 4;
  color: #333;
`;
export const CustomFeedbackCloseBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 12px;
  outline: none;
  border: none;
  color: #777;
  background-color: transparent;
`;
export const CustomFeedbackTopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 3%;
`;
export const CustomFeedbackBottomBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 3% 3%;
`;
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;
export const Title = styled.h2`
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  color: #333;
  margin: 0;
  padding: 0;
  @media (max-width: 520px) {
    font-size: 16px;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const CustomFeedbackTextArea = styled.textarea`
  box-sizing: border-box;
  height: 4rem;
  width: -webkit-fill-available;
  resize: none;
  outline: none;
  border-radius: 12px;
  border: none;
  box-shadow: 0px 1px 10px -1px rgba(34, 60, 80, 0.2) inset;
  padding: 0.5rem;
  font-family: 'Varela Round', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #333;
  &::placeholder {
    font-family: 'Varela Round', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #777;
  }

  @media (max-width: 520px) {
    font-size: 14px;
  }
`;
export const CustomSubmitFeedbackBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Varela Round', sans-serif;
  font-weight: 400;

  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background: transparent;

  color: #777; 
  border: none;

  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover &:focus {
    background: #fff;
    color: red; 
    text-decoration: underline;
  }
    box-shadow: rgba(0, 0, 0, 0.08) 0px 16px 32px;
  }
    &:active {
    color: #004085; 
  }
`;
export const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CheckboxItem = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 16px;
  font-family: 'Varela Round', sans-serif;
  font-weight: 400;
  @media (max-width: 940px) {
    font-size: 16px;
  }
  @media (max-width: 520px) {
    font-size: 14px;
  }
`;
export const CustomFeedbackForm = styled.form``;
