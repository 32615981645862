import { CustomMessage } from '../custom-message.enum';
import { Trace } from './types';

export const UploadFileTrace: Trace = {
  // canHandle: ({ payload }) => payload?.name === 'upload_file',
  canHandle: ({ type }) => type === 'upload_file',
  handle: ({ context }) => {
    context.messages.push({ type: CustomMessage.UPLOAD_FILE, payload: '' });
    return context;
  },
};
