import { baseUrl } from './base';
import { EventLog } from '../models/eventLog';

export interface PostEventLogsPayload {
  user_id: string;
  transcript_id: string;
  logs: EventLog[];
  json_obj: string;
}

export const postEventLogs = async (payload: PostEventLogsPayload): Promise<Response> => {
  const fullUrl = `${baseUrl}/logs`;

  return fetch(fullUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};
