import { useContext, useEffect, useState, useRef } from 'react';
import { MessageType } from '../../packages/react-chat/src/components/SystemResponse/constants';
import { MessageProps } from '../../packages/react-chat/src/components/SystemResponse/types';
import { TurnProps } from '../../packages/react-chat';

import { AppContext } from '../contextApp';

interface IUseMediaTurnProps {
  currentTurn: TurnProps;
  prevTurn: TurnProps;
  turnToFadeOut: TurnProps;
  turnsLength: number;
}

const filterMessages = (messages?: MessageProps[]) =>
  messages?.filter((msg: MessageProps) => msg.type !== MessageType.IMAGE && msg.type !== MessageType.BIO) || [];

export const useMediaTurn = ({ currentTurn, prevTurn, turnToFadeOut, turnsLength }: IUseMediaTurnProps) => {
  const { showFixedMedia, setShowFixedMedia } = useContext(AppContext);
  const fixedTurnRef = useRef<TurnProps>(null);
  const turnsLengthRef = useRef<number>(0);

  if (showFixedMedia) {
    turnsLengthRef.current = turnsLength;
  }

  useEffect(() => {
    const isCurrentTurnFixed = Object.hasOwn(currentTurn, 'messages')
      ? currentTurn.messages.some((m) => m.type === MessageType.IMAGE || m.type === MessageType.BIO)
      : false;

    if (!showFixedMedia && isCurrentTurnFixed) {
      setShowFixedMedia(true);
    }

    if (isCurrentTurnFixed) {
      fixedTurnRef.current = {
        ...currentTurn,
        messages: currentTurn?.messages?.filter((msg: MessageProps) => msg.type === MessageType.IMAGE || msg.type === MessageType.BIO),
      };
    }
  }, [currentTurn, showFixedMedia]);

  const isMediaFadeOut = turnsLengthRef.current < turnsLength && !showFixedMedia;

  return {
    currentTurn: { ...currentTurn, messages: filterMessages(currentTurn?.messages) },
    prevTurn: { ...prevTurn, messages: filterMessages(prevTurn?.messages) },
    turnToFadeOut: { ...turnToFadeOut, messages: filterMessages(turnToFadeOut?.messages) },
    fixedTurn: fixedTurnRef.current,
    isMediaFadeOut,
  };
};
