import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useEffect, useRef, useState, useContext } from 'react';
import { RuntimeContext } from '../context';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoints } from '../styled';

const DEFAULT_ACTIVE_INDEX = -1;

export const AutocompleteMessage = () => {
  const autocomplete = useRef(null);
  const [value, setValue] = useState('');
  const [isInputShow, setInputShow] = useState(true);
  const [activeItemIndex, setActiveItemIndex] = useState(DEFAULT_ACTIVE_INDEX);

  const [isOptionsShow, setOptionsShow] = useState(false);
  const { t } = useTranslation();

  const ctx = useContext(RuntimeContext);
  const runtime = ctx?.runtime;

  const onChange = (val: string) => {
    setValue(val);
  };

  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: import.meta.env.VF_GOOGLE_API_KEY,
  });

  const handleInputChange = (v) => {
    setValue(v);
    onChange(v);
  };

  const handleClickOutSide = (e) => {
    if (!autocomplete?.current?.contains(e.target)) {
      setOptionsShow(false);
    }
  };

  const hilightSearchText = (text: string) => {
    var pattern = new RegExp('(' + value + ')', 'gi');
    var new_text = text.replace(pattern, `<b>${value}</b>`);
    return new_text;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, []);

  useEffect(() => {
    placePredictions.length !== 0 ? setOptionsShow(true) : setOptionsShow(false);
  }, [placePredictions]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key !== 'Enter') return;

    if (isOptionsShow) {
      const selectedValue = placePredictions[activeItemIndex]?.description;
      setInputShow(false);
      setValue(selectedValue);
      runtime?.reply(selectedValue);
    } else {
      event.preventDefault();
      runtime?.reply(value);
      setInputShow(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == 'Escape') {
      runtime?.reply('Cancel address selection');
    }

    if (isOptionsShow) {
      let nextActiveIndex = activeItemIndex;
      if (event.key == 'Tab') {
        event.preventDefault();
        const selectedValue = placePredictions[activeItemIndex]?.description;
        if (selectedValue) {
          setValue(selectedValue);
        }
      }

      if (event.key == 'ArrowUp') {
        event.preventDefault();
        if (activeItemIndex === -1) {
          nextActiveIndex = placePredictions.length - 1;
        } else {
          nextActiveIndex = activeItemIndex - 1;
        }
      }

      if (event.key == 'ArrowDown') {
        if (activeItemIndex + 1 === placePredictions.length) {
          nextActiveIndex = -1;
        } else {
          nextActiveIndex = activeItemIndex + 1;
        }
      }

      setActiveItemIndex(nextActiveIndex);
    }
  };

  const handleItemClick = (description: string) => {
    setValue(description);
    setOptionsShow(false);
    setInputShow(false);
    onChange(description);
    runtime?.reply(description);
  };

  return isInputShow ? (
    <Wrapper ref={autocomplete} onKeyDown={handleKeyDown} className="autocomplete-container">
      <InputField
        type="search"
        isOptionsShow={isOptionsShow}
        autoFocus
        onKeyPress={handleKeyPress}
        value={value}
        onChange={(e) => {
          getPlacePredictions({ input: e.target.value });
          handleInputChange(e.target.value);
        }}
      />
      {isOptionsShow && (
        <ListWrapper>
          {placePredictions.map(({ description }, index) => (
            <ListItem onClick={() => handleItemClick(description)} key={index} isActive={index === activeItemIndex}>
              {<div dangerouslySetInnerHTML={{ __html: hilightSearchText(description) }} />}
            </ListItem>
          ))}
        </ListWrapper>
      )}
      <TextHelperContainer>
        <TextHelper>{t('autocomplete.textHelper')}</TextHelper>
      </TextHelperContainer>
    </Wrapper>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-width: 320px;
  max-width: 620px;
  margin-top: 16px;
  font-size: 20px;
  @media (max-width: 940px) : {
    font-size: 16px;
  }

  @media ${breakpoints.mobile} {
    min-width: 280px;
    font-size: 14px;
  }
`;

const InputField = styled.input`
  position: relative;
  width: 100%;

  font-family: 'Montserrat', Varela Round, sans-serif;
  color: rgb(119, 119, 119);
  border: 1px solid #e3e3e3;
  border-radius: ${(props) => (props.isOptionsShow ? '8px 8px 0  0' : '8px')};

  padding: 0 8px;
  height: 44px;
  outline: none;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

const ListItem = styled.button`
  text-align: left;
  padding: 12px 8px;
  width: 100%;
  background: ${(props) => (props.isActive ? 'rgba(60,64,67,0.08)' : '#fff')};
  outline: none;
  border: none;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(119, 119, 119);

  &:hover {
    background: rgba(60, 64, 67, 0.06);
  }
`;

const TextHelperContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TextHelper = styled.span`
  font-family: Montserrat;
  font-size: 0.75rem;
  color: #777;
`;
