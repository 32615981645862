import type { UserResource } from '@clerk/types';

type NameHelperParams = {
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
};

export const getFullName = ({ firstName, lastName, name }: NameHelperParams) => name || [firstName, lastName].join(' ').trim() || '';

export const getInitials = ({ firstName, lastName, name }: NameHelperParams) =>
  [(firstName || '')[0], (lastName || '')[0]].join('').trim() || (name || '')[0];

export const getIdentifier = (user: UserResource): string => {
  if (user.username) {
    return user.username;
  }

  if (user.primaryEmailAddress) {
    return user.primaryEmailAddress.emailAddress;
  }

  if (user.primaryPhoneNumber) {
    return user.primaryPhoneNumber.phoneNumber;
  }

  if (user.primaryWeb3Wallet) {
    return user.primaryWeb3Wallet.web3Wallet;
  }

  return '';
};

type IDable = { id: string };
export const currentSessionFirst = (id: string) => (a: IDable) => a.id === id ? -1 : 1;

const MILLISECONDS_IN_DAY = 86400000;
export function differenceInCalendarDays(a: Date, b: Date, { absolute = true } = {}): number {
  if (!a || !b) {
    return 0;
  }
  const utcA = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utcB = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  const diff = Math.floor((utcB - utcA) / MILLISECONDS_IN_DAY);
  return absolute ? Math.abs(diff) : diff;
}

export function normalizeDate(d: Date | string | number): Date {
  try {
    return new Date(d || new Date());
  } catch (e) {
    return new Date();
  }
}

type DateFormatRelativeParams = {
  date: Date | string | number;
  relativeTo: Date | string | number;
};

export type RelativeDateCase = 'previous6Days' | 'lastDay' | 'sameDay' | 'nextDay' | 'next6Days' | 'other';
type RelativeDateReturn = { relativeDateCase: RelativeDateCase; date: Date } | null;

export function formatRelative(props: DateFormatRelativeParams): RelativeDateReturn {
  const { date, relativeTo } = props;
  if (!date || !relativeTo) {
    return null;
  }
  const a = normalizeDate(date);
  const b = normalizeDate(relativeTo);
  const differenceInDays = differenceInCalendarDays(b, a, { absolute: false });

  if (differenceInDays < -6) {
    return { relativeDateCase: 'other', date: a };
  }
  if (differenceInDays < -1) {
    return { relativeDateCase: 'previous6Days', date: a };
  }
  if (differenceInDays === -1) {
    return { relativeDateCase: 'lastDay', date: a };
  }
  if (differenceInDays === 0) {
    return { relativeDateCase: 'sameDay', date: a };
  }
  if (differenceInDays === 1) {
    return { relativeDateCase: 'nextDay', date: a };
  }
  if (differenceInDays < 7) {
    return { relativeDateCase: 'next6Days', date: a };
  }
  return { relativeDateCase: 'other', date: a };
}

const enUS = {
  dates: {
    previous6Days: "Last {{ date | weekday('en-US','long') }} at {{ date | timeString('en-US') }}",
    lastDay: "Yesterday at {{ date | timeString('en-US') }}",
    sameDay: "Today at {{ date | timeString('en-US') }}",
    nextDay: "Tomorrow at {{ date | timeString('en-US') }}",
    next6Days: "{{ date | weekday('en-US','long') }} at {{ date | timeString('en-US') }}",
    numeric: "{{ date | numeric('en-US') }}",
  },
};

// localizationKeys is a function that get 2 parameters:
// 1. key: string - the key of the localization from the enUS object
// 2. params: Record<string, any> | undefined - the params of the localization
// the function returns a string that is the localization with the params
// for example: localizationKeys('dates.previous6Days', { date: relativeDate.date });
// will return: Last Thursday at 11:00 AM
function localizationKeys(key: string, params: Record<string, any> | undefined): string {
  let string = String(key.split('.').reduce((o: Record<string, any>, i: string) => o[i], enUS));
  const date = params?.date;
  // convert {{ date | weekday('en-US','long') }} to Thursday
  function weekday(locale: string, length: 'long' | 'short' | 'narrow') {
    return date.toLocaleDateString(locale, { weekday: length });
  }
  // convert {{ date | timeString('en-US') }} to 11:00 AM
  function timeString(locale: string) {
    return date.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' });
  }
  // convert {{ date | numeric('en-US') }} to 03/04/2021
  function numeric(locale: string) {
    return date.toLocaleDateString(locale);
  }

  // replace the {{ date | weekday('en-US','long') }} to Thursday
  const replaceWeekday = (str: string) => str.replace(/{{ date \| weekday\('en-US','long'\) }}/g, weekday('en-US', 'long'));
  // replace the {{ date | timeString('en-US') }} to 11:00 AM
  const replaceTime = (str: string) => str.replace(/{{ date \| timeString\('en-US'\) }}/g, timeString('en-US'));
  // replace the {{ date | numeric('en-US') }} to 03/04/2021
  const replaceNumeric = (str: string) => str.replace(/{{ date \| numeric\('en-US'\) }}/g, numeric('en-US'));

  // check if the string includes {{ date | weekday('en-US','long') }}
  if (string.includes('{{ date | weekday')) {
    string = replaceWeekday(string);
  }
  // check if the string includes {{ date | timeString('en-US') }}
  if (string.includes('{{ date | timeString')) {
    string = replaceTime(string);
  }
  // check if the string includes {{ date | numeric('en-US') }}
  if (string.includes('{{ date | numeric')) {
    string = replaceNumeric(string);
  }

  return string;
}

export const getRelativeToNowDateKey = (date: Date) => {
  const relativeDate = formatRelative({ date: date || new Date(), relativeTo: new Date() });
  if (!relativeDate) {
    return '';
  }
  switch (relativeDate.relativeDateCase) {
    case 'previous6Days':
      return localizationKeys('dates.previous6Days', { date: relativeDate.date });
    case 'lastDay':
      return localizationKeys('dates.lastDay', { date: relativeDate.date });
    case 'sameDay':
      return localizationKeys('dates.sameDay', { date: relativeDate.date });
    case 'nextDay':
      return localizationKeys('dates.nextDay', { date: relativeDate.date });
    case 'next6Days':
      return localizationKeys('dates.next6Days', { date: relativeDate.date });
    case 'other':
    default:
      return localizationKeys('dates.numeric', { date: relativeDate.date });
  }
};
