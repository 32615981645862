import { useRuntime, cuid } from '../packages/react-chat';
import { createNanoEvents } from 'nanoevents';
import { createContext, useMemo } from 'react';
import { useAuth, useUser } from '@clerk/clerk-react';

import { LiveAgentPlatform } from '../shared/live-agent-platform.enum';
import { AccountInfoTrace } from './traces/account-info.trace';
import { CalendarTrace } from './traces/calendar.trace';
import { PluginTrace } from './traces/plugin.trace';
import { TalkToAgentTrace } from './traces/talk-to-agent.trace';
import { VideoTrace } from './traces/video.trace';
import { VideoGeneratedTrace } from './traces/video.generated';
import { WaitForVideoTrace } from './traces/waitForVideo.trace';
import { BioInitializeTrace } from './traces/bio-is-initialize.trace';
import { VideoInitializeTrace } from './traces/video-initialize.trace';
import { AutocompleteTrace } from './traces/autocomplete-initialize.trace';
import { ExitImageModeTrace } from './traces/exit-image.trace';
import { ExitBioModeTrace } from './traces/exit-bio-mode.trace';
import { SendFrameTrace } from './traces/send-frame.trace';
import { UploadFileTrace } from './traces/upload-file.trace';

export interface RuntimeEvents {
  live_agent: (platform: LiveAgentPlatform) => void;
}

export interface RuntimeContextValue {
  runtime: ReturnType<typeof useRuntime>;
  subscribe: <K extends keyof RuntimeEvents>(event: K, callback: RuntimeEvents[K]) => void;
}

export const RuntimeContext = createContext<RuntimeContextValue | null>(null);

export const RuntimeProvider = ({ children }: React.PropsWithChildren) => {
  const { userId } = useAuth();
  const { user } = useUser();

  const emitter = useMemo(() => createNanoEvents<RuntimeEvents>(), []);
  const runtime = useRuntime({
    verify: { projectID: import.meta.env.VF_PROJECT_ID },
    versionID: import.meta.env.VF_VERSION_ID || 'production',
    session: { userID: `${userId}-${cuid()}` || `anonymous-${cuid()}` },
    user: { name: user?.fullName || 'Anonymous' },
    traces: [
      AccountInfoTrace,
      CalendarTrace,
      VideoTrace,
      VideoGeneratedTrace,
      PluginTrace,
      BioInitializeTrace,
      VideoInitializeTrace,
      AutocompleteTrace,
      WaitForVideoTrace,
      ExitImageModeTrace,
      ExitBioModeTrace,
      SendFrameTrace,
      UploadFileTrace,
      TalkToAgentTrace((platform) => emitter.emit('live_agent', platform)),
    ],
  });

  const subscribe = (event: keyof RuntimeEvents, callback: (data?: any) => void) => emitter.on(event, callback);

  return <RuntimeContext.Provider value={{ runtime, subscribe }}>{children}</RuntimeContext.Provider>;
};
