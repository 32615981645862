import { useEffect, useContext, useState } from 'react';
import { RuntimeContext } from '../context';
import { AppContext } from '../contextApp';
import { createVideo } from '../VideoMode/videoAPIService';
import { EventLogType } from '../models/eventLog';

type VideoGenCallProps = {
  address: string;
};

export const VideoGenCall = ({ address }: VideoGenCallProps) => {
  const [isVideoLoading, setVideoLoading] = useState(false);
  const appCtx = useContext(AppContext);
  const ctx = useContext(RuntimeContext);
  const runtime = ctx?.runtime;

  useEffect(() => {
    if (!isVideoLoading) {
      const callCreateVideo = async () => {
        const body = { video: { user_id: runtime?.session.userID || '', address } };
        try {
          await createVideo(body);

          appCtx.addEventLog([
            {
              type: EventLogType.VIDEO_STARTED_PROCESSING,
              data: {
                success: true,
              },
            },
          ]);
          setVideoLoading(true);
          runtime?.interact({ type: 'ok', payload: '' });
        } catch (e) {
          appCtx.addEventLog([
            {
              type: EventLogType.VIDEO_STARTED_PROCESSING,
              data: {
                success: false,
                msg: JSON.stringify(e),
              },
            },
          ]);
          setVideoLoading(false);
          runtime?.interact({ type: 'error', payload: JSON.stringify(e) });
        }
      };
      callCreateVideo();
    }
  }, []);

  return <></>;
};
