import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChatInput from '@/components/ChatInput';

import { Container, Watermark } from './styled';
export interface FooterProps {
  /**
   * If true, shows a watermark indicating that the conversation is powered by Voiceflow.
   */
  withWatermark: boolean;

  /**
   * If true, shows a prompt to start a new chat by calling the {@link onStart} handler.
   * If false, renders controls for the user to write a response.
   */
  hasEnded?: boolean | undefined;

  /**
   * A callback to start a new conversation.
   */
  onStart?: React.MouseEventHandler<HTMLButtonElement> | undefined;

  /**
   * A callback to submit a user response.
   */
  onSend?: ((message: string) => void) | undefined;

  isInputDisabled: boolean;
}

const Footer: React.FC<FooterProps> = ({ withWatermark, hasEnded, onSend, isInputDisabled }) => {
  const [message, setMessage] = useState('');
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (!isInputDisabled && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [isInputDisabled]);

  const handleSend = (): void => {
    if (!message) return;

    onSend?.(message);
    setMessage('');
  };

  return (
    <Container className="rtl-footer-container" withShadow={!!hasEnded} withWatermark={withWatermark}>
      {hasEnded || isInputDisabled || (
        // {/* // eslint-disable-next-line jsx-a11y/no-autofocus */}
        <ChatInput
          value={message}
          autoFocus
          onValueChange={setMessage}
          onSend={handleSend}
          disabled={isInputDisabled}
          ref={inputRef}
          placeholder={t('inputPlaceholder')}
        />
      )}
    </Container>
  );
};

/**
 * Footer for the chat widget; displays input controls or a prompt to restart the conversation.
 *
 * @see {@link https://voiceflow.github.io/react-chat/?path=/docs/components-chat-footer--running}
 */
export default Object.assign(Footer, {
  Container,
  Watermark,
});
