import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import CDropzone from './Dropzone';
import { UploadButton, UploaderWrapper } from './styled';

interface IFileUploaderProps {
  onFileChange: (x: File[]) => void;
  onFileUpload: () => void;
  onCancel: () => void;
}

const FileUploader = ({ onFileChange, onFileUpload, onCancel }: IFileUploaderProps) => {
  const { t } = useTranslation();
  const [isDisableUpload, setIsDisableUpload] = useState<boolean>(true);

  return (
    <UploaderWrapper>
      <CDropzone onChange={onFileChange} setIsDisableUpload={setIsDisableUpload} />
      <div className="buttons-container">
        <UploadButton onClick={onFileUpload} disabled={isDisableUpload}>
          {t('upload')}
        </UploadButton>
        <UploadButton onClick={onCancel}>{t('cancel')}</UploadButton>
      </div>
    </UploaderWrapper>
  );
};

export default FileUploader;
