import { useContext, useRef } from 'react';
import * as R from 'remeda';
import { match } from 'ts-pattern';

import Card from '@/components/Card';
import Image from '@/components/Image';
import Text from '@/components/Text';
import { TurnProps } from '@/types';

import Feedback, { FeedbackProps } from '../Feedback';
import { MessageType } from './constants';
import EndState from './state/end';
import { Controls, List, MessageContainer } from './styled';
import { MessageProps } from './types';

export interface SystemMessageProps extends React.PropsWithChildren {
  /**
   * An image URL for an avatar to associate this message with.
   */
  avatar?: string;

  /**
   * A unix timestamp indicating when this message was sent.
   */
  timestamp: number;

  /**
   * A single message to render with a {@link Message} component.
   */
  message?: MessageProps;

  /**
   * If true, renders an avatar next to the message.
   */
  withImage: boolean;

  /**
   * If provided, will display {@link Feedback} component.
   * @default false
   */
  feedback?: FeedbackProps | undefined;

  /**
   * If provided, will apply fade in animation for text.
   * @default false
   */
  isLast?: boolean;

  /**
   * If provided and true, will apply fade out animation for text.
   * @default false
   */
  isFadeOut?: boolean;

  turn: TurnProps;
}

const SystemMessage: React.FC<SystemMessageProps> = ({ feedback, message, withImage, children, isLast = false, isFadeOut = false, turn }) => {
  const controlsRef = useRef<HTMLSpanElement>(null);
  const MessageContainerRef = useRef<HTMLDivElement>(null);
  if (!children && message?.type === MessageType.END) {
    return <EndState />;
  }

  return (
    <>
      <Controls ref={controlsRef} />
      <MessageContainer
        ref={MessageContainerRef}
        withImage={withImage}
        scrollable={message?.type === MessageType.CAROUSEL}
        style={{ marginTop: isFadeOut ? -MessageContainerRef.current?.offsetHeight || 0 : 0 }}
        isFadeOut={isFadeOut}
      >
        <List className="rtl-response-list">
          {children ??
            match(message)
              .with({ type: MessageType.TEXT }, ({ text, audioMessage }) => (
                <>
                  <Text text={text} withFadeInAnimation={isLast} audioMessage={audioMessage} />
                  {feedback && <Feedback {...feedback} turn={turn} />}
                </>
              ))
              .with({ type: MessageType.IMAGE }, ({ url }) => <Image image={url} />)
              .with({ type: MessageType.CARD }, (props) => <Card {...R.omit(props, ['type'])} />)
              .otherwise(() => null)}
        </List>
      </MessageContainer>
    </>
  );
};

/**
 * An individual message within a system response.
 */
export default SystemMessage;
