import React, { useEffect, useState } from 'react';
import './MuteButton.css';

interface MuteButtonProps {
  onClick: (x: any) => void;
  className?: string;
  isMuted?: boolean;
  isVideoModal: boolean;
}

const MuteButton: React.FC<MuteButtonProps> = ({ onClick, isMuted = false, isVideoModal }) => {
  const [buttonOpacity, setButtonOpacity] = useState(1);
  const handleMouseMove = () => {
    setButtonOpacity(1);
    setTimeout(() => {
      setButtonOpacity(0);
    }, 2000);
  };

  useEffect(() => {
    if (isVideoModal) {
      document.addEventListener('mousemove', handleMouseMove);
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [isVideoModal]);

  return (
    <button className={isVideoModal ? 'mute-video-button' : 'mute-button'} onClick={onClick} style={{ opacity: isVideoModal ? buttonOpacity : 1 }}>
      <svg
        className={isVideoModal ? 'mute-img-video' : 'mute-img'}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 8.4666665 10.583333625"
        version="1.1"
        x="0px"
        y="0px"
      >
        <g transform="translate(1.9245e-5,-8.7709064e-5)" display="inline">
          <path
            d="m 57.564453,-34.189453 c -0.459963,-0.02006 -0.935173,0.09368 -1.345703,0.390625 l -6.628906,4.796875 H 44.974609 C 43.336796,-29.001953 42,-27.634122 42,-25.996094 v 3.992188 c 0,1.638028 1.336796,3.005859 2.974609,3.005859 h 4.615235 l 6.628906,4.796875 c 0.82106,0.594044 1.901569,0.456686 2.597656,-0.02148 0.696088,-0.478016 1.181641,-1.278931 1.181641,-2.222656 v -15.109376 c 0,-0.943729 -0.485553,-1.744636 -1.181641,-2.222656 -0.348043,-0.239008 -0.79199,-0.392051 -1.251953,-0.412109 z"
            transform="matrix(0.26458333,0,0,0.26458333,-10.583333,10.583333)"
          />
          {isMuted && (
            <path d="m 5.8983482,3.1135051 c -0.1036445,0.10338 -0.1036445,0.271273 0,0.374654 l 0.6066813,0.606681 -0.6066813,0.606165 c -0.1039903,0.103439 -0.1039903,0.271732 0,0.375171 0.1034388,0.10399 0.2717321,0.10399 0.3751709,0 l 0.6061644,-0.606682 0.6066814,0.606682 c 0.1032023,0.102612 0.2699014,0.102612 0.3731038,0 0.1039902,-0.103439 0.1039902,-0.271732 0,-0.375171 L 7.253821,4.0948401 7.8594687,3.4881591 c 0.1036444,-0.103381 0.1036444,-0.271274 0,-0.374654 -0.1158016,-0.115802 -0.2662542,-0.10685 -0.3731038,0 L 6.8796835,3.7201861 6.2735191,3.1135051 c -0.1162039,-0.116204 -0.2729412,-0.10223 -0.3751709,0 z" />
          )}
        </g>
      </svg>
    </button>
  );
};

export default MuteButton;
