import { CustomMessage } from '../custom-message.enum';
import { Trace } from './types';

export const VideoTrace: Trace = {
  canHandle: ({ type }) => type === 'video_component',
  handle: ({ context }, trace) => {
    const videoPayload = trace?.payload ? JSON.parse(trace?.payload) : '';
    context.messages.push({ type: CustomMessage.VIDEO, payload: videoPayload });
    return context;
  },
};
