import { serializeToMarkdown } from '@voiceflow/slate-serializer/markdown';
import React from 'react';

import Message from '@/components/Message';
import type { TextMessageProps } from '@/components/SystemResponse/types';
import { useTypewriter } from '../../../../../src/hooks/useTypewriter';
import Markdown from './Markdown';
import FadedText from './FadedText';

export interface DefaultTextProps {
  /**
   * text whether in string or slate format
   */
  text: TextMessageProps['text'];

  /**
   * whether use fade in animation or not
   */
  withFadeInAnimation: boolean;
}

const DefaultText: React.FC<DefaultTextProps> = ({ text, withFadeInAnimation = false, audioMessage }) => {
  const serializableText = typeof text === 'string' ? text : serializeToMarkdown(text);
  return (
    <Message from="system">
      {withFadeInAnimation ? (
        <FadedText text={serializableText} audioMessage={audioMessage} />
      ) : (
        <Markdown className="markdown-text">{serializableText}</Markdown>
      )}
    </Message>
  );
};

export default DefaultText;
