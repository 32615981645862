import { ClassName } from '@/constants';
import { tagFactory } from '@/hocs';
import { CSS, styled } from '@/styles';

const tag = tagFactory(ClassName.INPUT);

export const inputStyles: CSS = {
  zIndex: '16',
  height: '$md',
  boxSizing: 'border-box',
  // border: '1px solid rgba(115,115,118,0.3)',
  border: 'none',
  // borderRadius: '$1',
  // backgroundColor: '$white',
  backgroundColor: 'transparent',
  // boxShadow: '0 1px 12px $shadow2',
  trans: ['border-color'],
};

export const inputFocusStyles: CSS = {
  // border: '1px solid rgba(115,115,118,0.5)',
  outline: 'none',
  border: 'none',
  boxShadow: 'none',
};

export const Container = styled(tag('textarea'), {
  ...inputStyles,
  padding: '0 $4',
  caretColor: 'rgb(51, 51, 51)',
  typo: {},
  // color: '$black',
  fontSize: '20px',

  '&:focus': {
    ...inputFocusStyles,
    outline: 0,
  },

  '&::placeholder': {
    color: 'transparent',
  },
});
