import React, { useContext, useEffect, useRef, useState } from 'react';

import './SettingsMenu.css';
import { ReactComponent as Settings } from '../../../icons/settings.svg';
import BioMini from '../../Bio/Mini';

import { RuntimeContext } from '../../../context';
import { AppContext } from '../../../contextApp';
import LanguageMenu from '../../languageMenu/LanguageMenu';

export interface SettingsProps {
  isDisabled?: boolean;
}

const SettingsMenu: React.FC<SettingsProps> = ({ isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { runtime } = useContext(RuntimeContext)!;

  const { direction } = useContext(AppContext)!;

  // when the user clicks outside of the menu, close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [containerRef]);

  function handleOpen() {
    if (isDisabled) return;

    setIsOpen(!isOpen);
  }

  return (
    <div className="icon-container" ref={containerRef}>
      <div className={direction == 'ltr' ? 'settings-menu-container left' : 'settings-menu-container'} style={{ display: isOpen ? 'block' : 'none' }}>
        {/* We using display block/none to keep the bio component always running in the background */}
        <BioMini runtime={runtime} />
      </div>
      <div className="settings-icon-wrapper">
        <Settings className="settings-icon settings-icon-color" onClick={handleOpen} />
      </div>
    </div>
  );
};

export default SettingsMenu;
