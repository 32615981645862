import Chat from '@/components/Chat';
import { styled } from '@/styles';

export const ChatWindowContainer = styled('div', {
  height: '100%',

  [`& ${Chat.Container}`]: {
    height: '100%',
  },

  [`.chat-window-wrapper`]: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
      height: '100%',
    },
  },
});
