import { useContext, useEffect } from 'react';
import { useRuntime } from '../../packages/react-chat';
import { VideoGenType } from '../hooks/useVideoGen';
import { AppContext } from '../contextApp';

type WaitForVideoProps = {
  runtime: ReturnType<typeof useRuntime>;
};

export const WaitForVideo = ({ runtime }: WaitForVideoProps) => {
  const { isVideoReady } = useContext(AppContext);
  useEffect(() => {
    console.log('mounting wait for video message', isVideoReady);
    if (isVideoReady) {
      console.log('Sending video is ready');
      runtime.interact({ type: 'video_ready', payload: '' });
    }
    console.log('video ready', isVideoReady);
    return () => {
      console.log('unmounting wait for video message');
    };
  }, [isVideoReady]);

  return <></>;
};
