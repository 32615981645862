import React from 'react';

interface PlayVideoIconProps {
  width: string;
  height: string;
  onClick: () => void;
  className?: string;
}

export const PlayVideoIcon: React.FC<PlayVideoIconProps> = ({ className = '', onClick, width, height }) => {
  return (
    <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" version="1.1" width={width} height={height} viewBox="0 0 256 256">
      <defs></defs>
      <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
        <path
          d="M 81.73 50.284 c 4.068 -2.349 4.068 -8.22 0 -10.569 L 48.051 20.271 L 14.372 0.827 c -4.068 -2.349 -9.153 0.587 -9.153 5.284 V 45 v 38.889 c 0 4.697 5.085 7.633 9.153 5.284 l 33.679 -19.444 L 81.73 50.284 z"
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};
