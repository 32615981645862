import { useEffect, useContext } from 'react';
import { SystemResponse, useRuntime } from '../../packages/react-chat';
import Bio from '../components/Bio';
import { AppContext } from '../contextApp';

const delay = (time = 1500) => new Promise<void>((resolve) => setTimeout(() => resolve(), time));

export interface SystemMessageProps extends React.ComponentProps<(typeof SystemResponse)['SystemMessage']> {
  value: string;
  runtime: ReturnType<typeof useRuntime>;
}

export const BioMessage: React.FC<SystemMessageProps> = ({ value, runtime }) => {
  const { frameUrl } = useContext(AppContext);
  useEffect(() => {
    return () => {
      window.byObj.bioShowFullView = false;
    };
  }, []);

  const handleFinish = async () => {
    // run this function only once in 10 seconds to prevent double execution
    const AccepetInvokeInterval = 10000;
    const { bioIsCalibrated, bioShowFullView, bioLastInteract } = window.byObj;
    const isComponentInteractInTheLast5s = bioLastInteract && new Date().getTime() - bioLastInteract < AccepetInvokeInterval;
    if (bioIsCalibrated && !bioShowFullView) {
      if (isComponentInteractInTheLast5s) {
        return null;
      }
    }

    // FIXME: this is a hack to prevent the Bio component from being rendered twice
    window.byObj.bioIsCalibrated = true;
    window.byObj.bioLastInteract = new Date().getTime();

    await delay();

    return runtime.interact({ type: 'done', payload: '' });
  };

  return <Bio onFinish={() => handleFinish()} userId={runtime.session.userID} />;
};
