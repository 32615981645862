/**
 * Delay function that returns a promise
 */
export const delay = (time = 1500) => new Promise<void>((resolve) => setTimeout(() => resolve(), time));

/**
 * Safe parse JSON
 */
export function safeParseJSON(data: unknown) {
  if (typeof data === 'object') {
    return data;
  } else if (typeof data === 'string') {
    try {
      return JSON.parse(data);
    } catch (error) {
      throw new Error('Failed to parse string to JSON');
    }
  } else {
    throw new Error('Failed to parse JSON. Data is not a string or object');
  }
}
