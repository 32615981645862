import React, { useEffect, useRef } from 'react';
import { useExtractFaceEmotions } from '../../hooks/useExtractFaceEmotions';
import { VideoRecorder } from '../../utils/videoRecorder';

interface WebcamComponentProps {
  userId: string;
  isFrameSent: boolean;
}

const HumeWebcam: React.FC<WebcamComponentProps> = ({ userId, isFrameSent }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const recorderRef = useRef<VideoRecorder | null>(null);

  useEffect(() => {
    setTimeout(() => {
      const videoElement = videoRef.current;
      videoElement && onVideoReady(videoElement);
    }, 2000);
  }, []);

  useExtractFaceEmotions(userId, isFrameSent, recorderRef.current);

  async function onVideoReady(videoElement: HTMLVideoElement) {
    if (!recorderRef.current && canvasRef.current) {
      const recorder = await VideoRecorder.create(videoElement, canvasRef.current);
      recorderRef.current = recorder;
    }
  }

  return (
    <div style={{ display: 'none' }}>
      <video ref={videoRef} autoPlay playsInline />
      <canvas ref={canvasRef} />
    </div>
  );
};

export default HumeWebcam;
