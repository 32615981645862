import { useEffect, useState } from 'react';

import { LanguageIcon, ArrowIcon } from '../../icons';
import { DropdownWrapper, SelectedItem, DropdownItems, DropdownItem } from './styled';
interface LanguageMenuDropdownProps {
  options: { label: string; code: string }[];
  setSelectedLanguage: (x: string) => void;
  selectedLanguage: string;
}
export const LanguageMenuDropdown: React.FC<LanguageMenuDropdownProps> = ({ options, setSelectedLanguage, selectedLanguage }) => {
  const [selected, setSelected] = useState('');
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const res = options.find((obj) => {
      return obj.code == selectedLanguage;
    });
    res && setSelected(res.label);
  }, [selectedLanguage]);

  const handleSelectedItemClick = () => {
    setIsShown(!isShown);
  };
  const handleDropdownItemClick = (e) => {
    setSelected(e.target.innerText);
    setSelectedLanguage(e.target.getAttribute('data-value'));
    setIsShown(false);
    location.reload();
  };

  return (
    <DropdownWrapper>
      <SelectedItem onClick={handleSelectedItemClick}>
        <span className="language-icon">
          <LanguageIcon />
        </span>
        <span>{selected} </span>
        <span className={isShown ? 'arrow-icon dropdown-visible' : 'arrow-icon'}>
          <ArrowIcon />
        </span>
      </SelectedItem>

      <DropdownItems className={isShown ? 'visible' : 'hidden'}>
        {options.map(({ code, label }) => {
          return (
            <DropdownItem key={code} data-value={code} onClick={handleDropdownItemClick}>
              {label}
            </DropdownItem>
          );
        })}
      </DropdownItems>
    </DropdownWrapper>
  );
};
