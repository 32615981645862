import { CustomMessage } from '../custom-message.enum';
import { Trace } from './types';

export const BioInitializeTrace: Trace = {
  canHandle: ({ payload }) => payload?.name === 'bio_component',
  handle: ({ context }, trace) => {
    window.byObj.bioShowFullView = true;
    const bioPayload = trace?.payload?.body ? JSON.parse(trace?.payload?.body) : '';
    context.messages.push({ type: CustomMessage.BIO, payload: bioPayload });
    return context;
  },
};
