import { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'styled-react-modal';
import { useRuntime } from '../../packages/react-chat';
import { AppContext } from '../contextApp';
import { PlayVideoIcon } from '../components/nav-icons/play-video/PlayVideo';
import { CloseIcon } from '../components/nav-icons/close/Close';
import { EventLogType } from '../models/eventLog';
import './VideoModal.css';

interface VideoModalProps {
  url?: string;
  runtime: ReturnType<typeof useRuntime>;
}

const VideoModal: React.FC<VideoModalProps> = ({ url = '', runtime }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { setOpenVideoModal, isMuted, addEventLog } = useContext(AppContext);

  useEffect(() => {
    setOpenVideoModal(true);

    addEventLog([
      {
        type: EventLogType.VIDEO_OPENED,
        data: {
          success: true,
        },
      },
    ]);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted;
    }
  }, [isMuted, videoRef]);

  const moveToNextRuntimeStep = () => {
    runtime.interact({ type: 'next', payload: '' });
  };

  const cancelViewing = () => {
    runtime.interact({ type: 'cancel', payload: '' });
  };

  const handleVideoEnd = () => {
    console.log('Video has ended!');
    addEventLog([
      {
        type: EventLogType.VIDEO_WATCHED,
        data: {
          success: true,
        },
      },
    ]);

    setOpenVideoModal(false);
    setIsOpen(!isOpen);
    moveToNextRuntimeStep();
  };

  function toggleModal() {
    addEventLog([
      {
        type: EventLogType.VIDEO_CANCELLED,
        data: {
          success: true,
        },
      },
    ]);
    setOpenVideoModal(false);
    setIsOpen(!isOpen);
    cancelViewing();
  }

  const onPlayClick = () => {
    if (videoRef.current?.paused) {
      videoRef.current.play();
      setIsPaused(false);
    } else {
      videoRef.current?.pause();
      setIsPaused(true);
    }
  };

  return (
    <div>
      <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        {url && (
          <>
            <video onClick={onPlayClick} className="video" ref={videoRef} onEnded={handleVideoEnd} width="1000">
              <source src={url} type="video/mp4" />
              Your browser does not support the video element.
            </video>
            {isPaused && <PlayVideoIcon onClick={onPlayClick} className="play-video-icon" width="10%" height="10%" />}
            <CloseIcon className="close" onClick={toggleModal} width="5%" height="5%" />
          </>
        )}
      </StyledModal>
    </div>
  );
};

export default VideoModal;

const StyledModal = Modal.styled`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;
