import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../../../../src/contextApp';
import { splitTextBySentences, isSpecialSentenceRegExp, wordToPauseRegExp } from '../../utils/messages';
import { DEFAULT_SENTENCE_DELAY, DEFAULT_WORD_DELAY, COMMA_DELAY } from '../../constants';
import Markdown from './Markdown';
import './Markdown.css';
export interface FadedTextProps {
  text: string;
}

const FadedText: React.FC<FadedTextProps> = ({ text, audioMessage }) => {
  const { isMuted } = useContext(AppContext);

  useEffect(() => {
    const getAudio = async () => {
      const audio = await audioMessage;
      if (!isMuted) {
        if (audio?.currentTime === 0) {
          audio?.play();
        }
      } else {
        audio?.pause();
      }
    };

    getAudio();
  }, [audioMessage, isMuted]);

  const ITALIC_PREFIX = '*';
  const sentenceDelay = DEFAULT_SENTENCE_DELAY / 1000;
  const wordDelay = DEFAULT_WORD_DELAY / 1000;
  const commaDelay = COMMA_DELAY / 1000;

  const putWordToSpan = (
    word: string,
    sentenceIndex: number,
    wordIndex: number,
    extraDelay: number = 0,
  ): string => {
    const delay = (wordIndex + 1) * wordDelay + sentenceDelay * sentenceIndex + extraDelay;
    const newlineLastIndex = word.lastIndexOf('\n'); // Double \n generated <p> tag
    return `${word.slice(0, newlineLastIndex + 1)}<span style="animation: fade-in 0.8s ${delay}s forwards cubic-bezier(0.11, 0, 0.5, 0)">${word.slice(newlineLastIndex + 1)}</span>`;
  };

  let specialMarkupSentenceDelayDelta = 0;
  let commaPauseDelayDelta = 0;
  let totalWordIndex = 0;

  const sentences: string[] = splitTextBySentences(text);

  const formattedText = sentences.map((sentence, sIndex) => {
    // A special invisible sentence that is used for markup only and shouldn't have an animation
    if (isSpecialSentenceRegExp.test(sentence)) {
      // To prevent redundant sentence segment delay
      specialMarkupSentenceDelayDelta += 1;
      return sentence;
    }
    const isTextItalic = sIndex === 0 && sentence[sIndex].charAt(0) === ITALIC_PREFIX;
    const sentenceText = `${sentence.split(' ').map((word, wIndex) => {
      const result = putWordToSpan(word, sIndex - specialMarkupSentenceDelayDelta, totalWordIndex, commaPauseDelayDelta);
      totalWordIndex += 1;
      if (wordToPauseRegExp.test(word)) {
        commaPauseDelayDelta += commaDelay;
      }
      return result;
    }).join(' ')}`;
    return isTextItalic
      ? `${ITALIC_PREFIX}${sentenceText.replaceAll(/\*/gi, '')}${ITALIC_PREFIX}`
      : sentenceText;
  })
    .join('');

  return <Markdown className="markdown">{formattedText}</Markdown>;
};

export default FadedText;
