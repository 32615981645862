import { IndicatorContainer } from './styled';
import { GooSpinner } from '../../../../../src/components/spinners';

export interface IndicatorProps {}

const Indicator: React.FC<IndicatorProps> = () => (
  <IndicatorContainer withImage center>
    <GooSpinner size={40} loading unit="px" />
  </IndicatorContainer>
);

export default Indicator;
