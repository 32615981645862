import { ShenaiSDK, type InitializationSettings } from 'shenai-sdk';
import { getInitResultString } from './utils';

declare global {
  interface Window {
    byObj?: Record<string, any>;
  }
}

let shenaiSDK: ShenaiSDK | null = null;
let initializeSDK: () => Promise<void> = () => Promise.resolve();
let deinitializeSDK: () => void = () => {};

function init() {
  // Shen.AI SDK is not available on the server side, so we need to check if we are on the client side
  if (typeof window !== 'undefined') {
    // FIXME: this is a hack to prevent multiple initialization
    window.byObj = Object.assign(window.byObj ?? {}, {
      bioInit: false,
      bioIsCalibrated: false,
      bioShowFullView: false,
      bioLastInteract: 0,
    });

    // import('shenai-sdk')
    return import('/shenai-sdk/index.mjs?url')
      .then((sdk) =>
        sdk.default({
          onRuntimeInitialized: () => {
            // console.log('Shen.AI Runtime initialized');
          },
        })
      )
      .then((sdk) => {
        shenaiSDK = sdk;

        deinitializeSDK = () => {
          // FIXME: this is a hack to reinitialize the SDK
          window.byObj.bioInit = false;
          window.byObj.bioIsCalibrated = false;
          shenaiSDK?.deinitialize();
        };

        initializeSDK = (canvasSelectorID, bySettings = {}) => {
          return new Promise<void>((resolve, reject) => {
            // FIXME: this is a hack to prevent multiple initialization
            if (window.byObj.bioInit) {
              // console.log('Shen.AI License already initialized');
              // shenaiSDK?.attachToCanvas(canvasSelectorID);
              // return;
              resolve();
            }

            const bySettingsDefault: InitializationSettings = {
              precisionMode: shenaiSDK!.PrecisionMode.RELAXED,
              operatingMode: shenaiSDK!.OperatingMode.MEASURE,
              measurementPreset: shenaiSDK!.MeasurementPreset.INFINITE_METRICS,
              cameraMode: shenaiSDK!.CameraMode.FACING_USER,
              showUserInterface: false,
              showFacePositioningOverlay: true,
              showVisualWarnings: true,
              enableCameraSwap: false,
              showFaceMask: true,
              showBloodFlow: true,
              proVersionLock: false,
              hideShenaiLogo: true,
            };

            // const bySettingsOverride: Partial<InitializationSettings> = {
            //   precisionMode: shenaiSDK!.PrecisionMode[bySettings?.precisionMode || 'RELAXED'],
            //   operatingMode: shenaiSDK!.OperatingMode[bySettings?.operatingMode || 'MEASURE'],
            //   measurementPreset: shenaiSDK!.MeasurementPreset[bySettings?.measurementPreset || 'INFINITE_HR'],
            //   cameraMode: shenaiSDK!.CameraMode[bySettings?.cameraMode || 'FACING_USER'],
            // };

            // const bySettingsFinal: InitializationSettings = {
            //   ...bySettingsDefault,
            //   ...bySettingsOverride,
            // };

            // console.log('Shen.AI License initializing...', shenaiSDK);
            shenaiSDK?.initialize(import.meta.env.VF_SHENAI_SDK_API_KEY, '', bySettingsDefault, (res) => {
              if (res === shenaiSDK?.InitializationResult.OK) {
                // FIXME: this is a hack to prevent multiple initialization
                window.byObj.bioInit = true;

                // shenaiSDK?.attachToCanvas(canvasSelectorID);

                // The SDK can record the measurement video and send it to MX Labs for analysis.
                shenaiSDK?.setRecordingEnabled(false);

                resolve();
              } else {
                console.error('License initialization problem: ' + getInitResultString(shenaiSDK, res));
                reject();
              }
            });
          });
        };

        return {
          shenaiSDK,
          initializeSDK,
          deinitializeSDK,
        };
      })
      .catch((err) => {
        console.error('Shen.AI SDK error: ', err);
      });
  }
}

export function initShenAi() {
  if (!shenaiSDK || !initializeSDK) {
    return init();
  }

  return {
    shenaiSDK,
    initializeSDK,
    deinitializeSDK,
  };
}
