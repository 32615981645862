import { styled } from 'styled-components';

export const UploaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .container {
    width: 100%;
    display: flex;
    background: transparent;
    flex-direction: column;
    gap: 20px;
  }

  .opacity-block {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.1;
    z-index: -1;
    border-radius: 12px;
  }
  .dropzone {
    position: relative;
    padding: 1rem;
    border-radius: 12px;
    color: #777;
    border: dashed #777 2px;
    background-color: transparent;
    text-align: center;
    cursor: move;
    &:hover {
      border: dashed #fff 2px;
    }
  }
  .buttons-container {
    display: flex;
    gap: 20px;
  }
`;
export const UploadButton = styled.button`
  border-radius: 12px;
  background-color: white;
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  color: #333;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &:hover,
  &:focus {
    background: #fff;
    color: #777;
    text-decoration: underline;
  }
`;

export const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16;
`;

export const Thumb = styled.div`
  display: inline-flex;

  border-radius: 12px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  .overlay {
    left: 0;
    top: 0;
    display: none;
    border-radius: 12px;
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    justify-content: center;
    align-items: center;
    svg {
      width: 80px;
      height: 80px;
    }
  }
  &:hover .overlay {
    display: flex;
    background: #333;
    opacity: 0.2;
  }
`;

export const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`;

export const ThumbImg = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;

export const TextContent = styled.p`
  display: flex;
  flex-direction: column;
  color: #777;
  line-height: 20px;
  font-weight: 400;
  text-decoration: none;

  .text {
    font-size: 18px;
  }
  .description {
    font-size: 16px;
  }
`;
