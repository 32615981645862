import { useContext, useRef } from 'react';
import { RuntimeContext } from './context';
import { AppContext } from './contextApp';
import { FeedbackName, SystemResponse, TurnProps, TurnType, UserResponse } from '../packages/react-chat';
import { BY_BIO_MODE, BY_IMG_MODE, BY_TXT_MODE, BY_VID_MODE, CustomMessage } from './custom-message.enum';
import { match } from 'ts-pattern';
import { BioMessage } from './messages/BioMessage.component';
import VideoModal from './VideoMode/VideoModal';
import { VideoGenCall } from './messages/VideoGenCall';
import { AutocompleteMessage } from './messages/AutocompleteMessage.component';
import { MessageType } from '@/components/SystemResponse/constants';
import { WaitForVideo } from './messages/WaitForVideo';
import { ExitMediaMode } from './messages/ExitMediaMode';
import { SendFrameMessage } from './messages/SendFrameMessage';
import './TurnMessages.css';
import { UploadFileMessage } from './messages/UploadFileMessage.component';

type TurnMessagesProps = {
  turn: TurnProps;
  isFadeOut?: boolean;
  isLast?: boolean;
};

export default function TurnMessages({ turn, isFadeOut, isLast }: TurnMessagesProps) {
  const ctx = useContext(RuntimeContext);
  const runtime = ctx?.runtime;
  const appCtx = useContext(AppContext);
  const { videoUrl } = appCtx;
  const turnBlockRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={turnBlockRef}
      className={isFadeOut ? 'fade-out turn-messages-container' : 'turn-messages-container'}
      style={{ marginTop: isFadeOut ? -turnBlockRef.current?.offsetHeight || 0 : 0 }}
      //   style={{ marginTop: isFadeOut ? '-100%' : 0 }}
    >
      {match(turn)
        .with({ type: TurnType.USER }, ({ id, type: _, ...rest }) => <UserResponse {...rest} key={id} />)
        .with({ type: TurnType.SYSTEM }, ({ id, type: _, ...rest }) => (
          <SystemResponse
            avatar={''}
            {...rest}
            key={id}
            feedback={
              // rest.feedback ?
              {
                question: '',
                onClick: (feedback: FeedbackName, feedbackData) => {
                  runtime.feedback(feedback, feedbackData, rest.messages, turn);
                },
              }
              // : undefined
            }
            Message={({ message, ...props }) =>
              match(message)
                .with({ type: CustomMessage.BIO }, ({ payload: { data } }) => {
                  return <BioMessage {...props} value={data} runtime={runtime} />;
                })
                .with({ type: CustomMessage.VIDEO }, ({ payload: { url } }) => <VideoModal url={url} runtime={runtime} />)
                .with({ type: CustomMessage.VIDEO_GENERATED }, () => <VideoModal url={videoUrl || ''} runtime={runtime} />)
                .with({ type: CustomMessage.AUTOCOMPLETE }, () => (isLast ? <AutocompleteMessage /> : <></>))
                .with({ type: CustomMessage.VIDEO_GEN_TRIGGER }, ({ payload: { address } }) => <VideoGenCall address={address} />)
                .with({ type: CustomMessage.WAITFORVIDEO }, () => <WaitForVideo runtime={runtime} />)
                .with({ type: CustomMessage.EXIT_IMAGE_MODE }, () => <ExitMediaMode runtime={runtime} messageType={CustomMessage.EXIT_IMAGE_MODE} />)
                .with({ type: CustomMessage.EXIT_BIO_MODE }, () => <ExitMediaMode runtime={runtime} messageType={CustomMessage.EXIT_BIO_MODE} />)
                .with({ type: CustomMessage.SEND_FRAME }, () => <SendFrameMessage runtime={runtime} messageType={CustomMessage.SEND_FRAME} />)
                .with({ type: CustomMessage.UPLOAD_FILE }, () => <UploadFileMessage runtime={runtime} />)

                .otherwise(() =>
                  message &&
                  message.type == MessageType.TEXT &&
                  (message.text === BY_BIO_MODE ||
                    message.text === BY_TXT_MODE ||
                    message.text === BY_IMG_MODE ||
                    message.text === BY_VID_MODE) ? null : (
                    <SystemResponse.SystemMessage {...props} message={message} isLast={isLast} turn={turn} />
                  )
                )
            }
            isLast={isLast}
          />
        ))
        .exhaustive()}
    </div>
  );
}
