import { useCallback, useEffect, useRef, useState } from 'react';
import { match } from 'ts-pattern';
import { serializeToMarkdown } from '@voiceflow/slate-serializer/markdown';

import { useDidUpdateEffect } from '@/hooks';

import { MessageProps } from './types';
import { DEFAULT_MESSAGE_DELAY, DEFAULT_FIRST_MESSAGE_DELAY, DEFAULT_INDICATOR_DELAY } from '../../constants';
import { getMessageDelay, getMessageAudio } from '../../utils/messages';
import { MessageType } from './constants';

export * from './types';

enum AnimationType {
  MESSAGE = 'message',
  INDICATOR = 'indicator',
}

type Animation<T extends AnimationType = AnimationType> = {
  [AnimationType.MESSAGE]: { type: AnimationType.MESSAGE; message: MessageProps };
  [AnimationType.INDICATOR]: { type: AnimationType.INDICATOR; messageDelay: number };
}[T];

const createAnimateIndicator = (messageDelay: number = DEFAULT_MESSAGE_DELAY): Animation<AnimationType.INDICATOR> => ({
  type: AnimationType.INDICATOR,
  messageDelay,
});

export const useAnimatedMessages = ({
  messages,
  isLast,
  withAudio,
}: {
  messages: MessageProps[];
  isLast: boolean | undefined;
  withAudio: boolean;
}) => {
  const shouldAnimate = useRef(isLast && !!messages.length);
  const [complete, setComplete] = useState(!shouldAnimate.current);
  const [showIndicator, setShowIndicator] = useState(shouldAnimate.current);
  const [visibleMessages, setVisibleMessages] = useState(shouldAnimate.current ? [] : messages);

  const endAnimation = useCallback(() => {
    setComplete(true);
    setShowIndicator(false);
  }, []);

  useEffect(() => {
    if (!shouldAnimate) return undefined;

    const animations = messages
      .flatMap<Animation>((message, i) => {
        const delay = message.type === MessageType.TEXT ? getMessageDelay(message) : DEFAULT_FIRST_MESSAGE_DELAY;

        let audioMessage = null;
        //silence 11labs
        if (withAudio && message.type === MessageType.TEXT) {
          const serializableText = typeof message.text === 'string' ? message.text : serializeToMarkdown(message.text);
          audioMessage = getMessageAudio(serializableText);
        }

        return [
          createAnimateIndicator(delay),
          {
            type: AnimationType.MESSAGE,
            message: { ...message, delay, audioMessage },
          },
        ];
      })
      .filter((notNull) => notNull);

    let timer: NodeJS.Timeout;
    const setTimer = (callback: VoidFunction, messageDelay: number) => {
      if (messageDelay === 0) {
        callback();
        return;
      }

      timer = setTimeout(() => {
        callback();
      }, messageDelay);
    };

    const animate = () => {
      if (!shouldAnimate.current) return;

      const next = animations.shift();
      if (!next) {
        endAnimation();
        return;
      }

      match(next)
        .with({ type: AnimationType.MESSAGE }, ({ message }) => {
          setShowIndicator(false);
          setVisibleMessages((prev) => [...prev, message]);
          const delay = [MessageType.TEXT, MessageType.IMAGE, MessageType.BIO, MessageType.AUTOCOMPLETE].includes(message?.type)
            ? message?.delay || DEFAULT_MESSAGE_DELAY
            : 0;

          setTimer(animate, delay);
        })
        .with({ type: AnimationType.INDICATOR }, ({ messageDelay = DEFAULT_MESSAGE_DELAY }) => {
          setShowIndicator(true);
          setTimer(animate, visibleMessages.length > 0 ? DEFAULT_MESSAGE_DELAY : DEFAULT_FIRST_MESSAGE_DELAY);
        })
        .exhaustive();
    };

    animate();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useDidUpdateEffect(() => {
    if (!isLast) {
      shouldAnimate.current = false;
      endAnimation();
      setVisibleMessages(messages);
    }
  }, [isLast]);

  return {
    complete,
    showIndicator,
    visibleMessages,
  };
};
