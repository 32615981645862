import { Base64 } from '../hooks/useExtractFaceEmotions';

export const blobToBase64 = async (blob: Blob): Promise<Base64> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(blob);
  });
};

export function canvasToImageBlob(canvas: HTMLCanvasElement, format: string = 'image/png'): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const handleBlob = (blob: Blob | null) => {
      if (blob) {
        resolve(blob);
      } else {
        reject('Could not parse blob');
      }
    };
    canvas.toBlob(handleBlob, format, 0.92);
  });
}
