import React, { createContext, useState, useRef, useEffect } from 'react';

import { EventLog, EventLogType } from './models/eventLog';
import i18n from './i18';

export interface AppContextValue {
  mode: string; // 'text' | 'image' | 'video'
  setMode: React.Dispatch<React.SetStateAction<string>>;
  openVideoModal: boolean;
  setOpenVideoModal: React.Dispatch<React.SetStateAction<boolean>>;
  isBioComponentReady: boolean;
  setIsBioComponentReady: React.Dispatch<React.SetStateAction<boolean>>;
  isVideoWatched: boolean;
  setIsVideoWatched: React.Dispatch<React.SetStateAction<boolean>>;
  isBioCalibrated: boolean;
  setIsBioCalibrated: React.Dispatch<React.SetStateAction<boolean>>;
  hasBioDispatch: boolean;
  setHasBioDispatch: React.Dispatch<React.SetStateAction<boolean>>;
  eventLogs: EventLog[];
  setEventLogs: React.Dispatch<React.SetStateAction<EventLog[]>>;
  addEventLog: (_: EventLog[]) => void;
  getEventLogs: () => EventLog[];
  clearEventLogs: () => void;
  visibleMessages: object[];
  setVisibleMessages: React.Dispatch<React.SetStateAction<object[]>>;
  isMuted: boolean;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
  isMessageAnimationCompleted: boolean;
  setIsMessageAnimationCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  showFixedMedia: boolean;
  setShowFixedMedia: React.Dispatch<React.SetStateAction<boolean>>;
  isVideoReady: boolean;
  setVideoReady: React.Dispatch<React.SetStateAction<boolean>>;
  videoUrl: string;
  setVideoUrl: React.Dispatch<React.SetStateAction<string>>;
  isRTL: boolean;
  setIsRTL: React.Dispatch<React.SetStateAction<boolean>>;
  frameUrl: string;
  setFrameUrl: React.Dispatch<React.SetStateAction<string>>;
  cameraId: string;
  setCameraId: React.Dispatch<React.SetStateAction<string>>;
  direction: string;
  setDirection: React.Dispatch<React.SetStateAction<string>>;
}

export const AppContext = createContext<AppContextValue>({
  mode: '',
  setMode: () => {},
  openVideoModal: false,
  setOpenVideoModal: () => {},
  isBioComponentReady: false,
  setIsBioComponentReady: () => {},
  isVideoWatched: false,
  setIsVideoWatched: () => {},
  isBioCalibrated: false,
  setIsBioCalibrated: () => {},
  hasBioDispatch: false,
  setHasBioDispatch: () => {},
  eventLogs: [],
  setEventLogs: () => {},
  addEventLog: () => {},
  getEventLogs: () => {},
  clearEventLogs: () => {},
  setVisibleMessages: () => {},
  visibleMessages: [],
  isMuted: false,
  setIsMuted: () => {},
  isMessageAnimationCompleted: false,
  setIsMessageAnimationCompleted: () => {},
  showFixedMedia: false,
  setShowFixedMedia: () => {},
  isVideoReady: false,
  setVideoReady: () => {},
  videoUrl: '',
  setVideoUrl: () => {},
  isRTL: false,
  setIsRTL: () => {},
  frameUrl: '',
  setFrameUrl: () => {},
  cameraId: '',
  setCameraId: () => {},
  direction: '',
  setDirection: () => {},
});

export const AppProvider = ({ children }: React.PropsWithChildren) => {
  // const [mode, setMode] = useState('text');
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [isBioComponentReady, setIsBioComponentReady] = useState(false);
  // const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isBioCalibrated, setIsBioCalibrated] = useState(false);
  const [isVideoReady, setVideoReady] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [visibleMessages, setVisibleMessages] = useState([]);
  // const [hasBioDispatch, setHasBioDispatch] = useState(false);
  const eventLogsRef = useRef<EventLog[]>([]);
  const [isMuted, setIsMuted] = useState(true);
  const [isMessageAnimationCompleted, setIsMessageAnimationCompleted] = useState(false);
  const [showFixedMedia, setShowFixedMedia] = useState(false);
  const [isRTL, setIsRTL] = useState(false);
  const [frameUrl, setFrameUrl] = useState<string>('');
  const [cameraId, setCameraId] = useState<string>('');
  const [direction, setDirection] = useState<string>('');

  const addEventLog = (logs: EventLog[]) => {
    const transriptLog = logs.find((log) => log.type === EventLogType.VF_TRANSCRIPTS);
    // Replace a single transcripts log by new data
    const newLogs = transriptLog
      ? [
          ...eventLogsRef.current.filter((log) => log.type !== EventLogType.VF_TRANSCRIPTS),
          ...logs.map((log) => ({
            ...log,
            timestamp: log.timestamp || Date.now(),
          })),
        ]
      : [
          ...eventLogsRef.current,
          ...logs.map((log) => ({
            ...log,
            timestamp: log.timestamp || Date.now(),
          })),
        ];
    eventLogsRef.current = newLogs;
  };

  const getEventLogs = () => eventLogsRef.current;

  const clearEventLogs = () => {
    eventLogsRef.current = [];
  };

  return (
    <AppContext.Provider
      value={{
        // mode,
        // setMode,
        openVideoModal,
        setOpenVideoModal,
        isBioComponentReady,
        setIsBioComponentReady,
        isMuted,
        setIsMuted,
        // isVideoWatched,
        // setIsVideoWatched,
        isBioCalibrated,
        setIsBioCalibrated,
        // hasBioDispatch,
        // setHasBioDispatch,
        eventLogs: eventLogsRef.current,
        addEventLog,
        getEventLogs,
        clearEventLogs,
        visibleMessages,
        setVisibleMessages,
        isVideoReady,
        setVideoReady,
        isMessageAnimationCompleted,
        setIsMessageAnimationCompleted,
        showFixedMedia,
        setShowFixedMedia,
        videoUrl,
        setVideoUrl,
        isRTL,
        setIsRTL,
        frameUrl,
        setFrameUrl,
        cameraId,
        setCameraId,
        direction,
        setDirection,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
